import { JokeItem } from '../carousel/types/items'
import ga4RevealWidgetClickHandler from '../ga4_support/blocks/reveal_widget'
import { refreshVisibleAds } from '../utilities/ad_behaviors'
import { shuffle } from '../utilities'
import i18n from '../../../utilities/translation'
import GA4Handler from '../ga4_support/GA4Handler'
const language = document.documentElement.lang

const BASE_CLICK_TEXT = i18n('Tap to reveal the punchline.', language)
const BASE_REFRESH_TEXT = i18n('Tap to hear another joke.', language)

let displayCount = 0

const initJokesWidgetBlock = (container: any, initialList: JokeItem[]) => {
  let unseenContent = shuffle(initialList)
  let isRevealed = false

  GA4Handler.impressionHandleEvent(container as HTMLElement)

  const widgetInnerContainer = container.querySelector('.reveal-widget__inner-container')

  // Init template
  const widgetItem = container.querySelector('.jokes-widget')
  const activeRegion: any = container.querySelector('.reveal-widget__dialog-region')
  const widgetMessageContainer = container.querySelector('.reveal-widget__message')
  const jokeSetupContainer = widgetMessageContainer.querySelector('.jokes-widget--setup')
  const jokePunchlineContainer = widgetMessageContainer.querySelector('.jokes-widget--punchline')
  const actionButtons: any = container.querySelector('.block-item__actions')
  const footer: any = container.querySelector('.reveal-widget__footer')

  container.setAttribute('data-gtm-share-text', 'joke not revealed')

  // noinspection DuplicatedCode
  const hideActionButtons = () => {
    actionButtons.setAttribute('tabindex', '-1')
    actionButtons.setAttribute('aria-hidden', 'true')
    actionButtons.classList.add('hidden')
  }

  const showActionButtons = () => {
    actionButtons.setAttribute('tabindex', '0')
    actionButtons.removeAttribute('aria-hidden')
    actionButtons.classList.remove('hidden')
  }

  const showMessage = () => {
    widgetItem.classList.remove('reveal-widget--hide-message')
    widgetItem.classList.add('reveal-widget--show-message')

    footer.classList.remove('reveal-widget--footer-disappear')
    footer.classList.add('reveal-widget--footer-appear')
    container.setAttribute('data-gtm-feature', 'refresh')
    showActionButtons()
  }

  const hideMessage = () => {
    widgetItem.classList.remove('reveal-widget--show-message')
    widgetItem.classList.add('reveal-widget--hide-message')

    footer.classList.remove('reveal-widget--footer-appear')
    footer.classList.add('reveal-widget--footer-disappear')
    container.setAttribute('data-gtm-feature', 'show')
    container.setAttribute('data-gtm-share-text', 'joke not revealed')
    hideActionButtons()
  }

  const refreshJoke = ({ setup, punchline }) => {
    jokeSetupContainer.innerHTML = setup
    jokePunchlineContainer.innerHTML = punchline
    container.setAttribute('data-gtm-feature', 'show')
    container.setAttribute('data-mp-share-jokes-text', `${setup} \n ${punchline}`)
    container.setAttribute('data-gtm-share-text', `${setup} \n ${punchline}`)
  }

  let isClickedOnce = false
  let activeJoke = unseenContent.pop()

  refreshJoke(activeJoke)

  const refreshContent = () => {
    if (!isClickedOnce) {
      widgetInnerContainer.removeAttribute('aria-label')
      widgetInnerContainer.setAttribute('aria-labelledby', 'jokes-widget__dialog-region')

      isClickedOnce = true
    }

    if (!isRevealed) {
      activeRegion.innerText =
        `${activeJoke.setup} \n ${activeJoke.punchline}` + '\n\n' + BASE_REFRESH_TEXT
      showMessage()
      ga4RevealWidgetClickHandler(
        container,
        'editorial',
        'show',
        activeJoke.setup,
        activeJoke.punchline,
        ++displayCount
      )
      refreshVisibleAds()
    } else {
      // Reset unseen fortunes if there are none left
      if (!unseenContent.length) {
        unseenContent = shuffle(initialList)
      }
      // Gets and removes last fortune to not repeat the same fortunes
      activeJoke = unseenContent.pop()
      hideMessage()
      refreshJoke(activeJoke)
      activeRegion.innerText = `${activeJoke.setup} \n\n ${BASE_CLICK_TEXT}`
      ga4RevealWidgetClickHandler(container, 'editorial', 'refresh', '', '')
    }
    isRevealed = !isRevealed
  }

  hideActionButtons()
  widgetInnerContainer.addEventListener('click', refreshContent)
  widgetInnerContainer.addEventListener('keyup', (e: any) => {
    if (e.type !== 'keyup') {
      return
    }
    if (['Enter', ' '].includes((e as any).key)) {
      refreshContent()
    }
  })
}

export default initJokesWidgetBlock
