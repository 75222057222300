const getTimerTemplate = () => {
  return `
    <div class="timer">
      <div class="timer-number"></div>
      <svg>
        <circle r="12" cx="13" cy="13" fill='transparent' stroke='black' stroke-opacity='0.1' stroke-width='2px'></circle>
        <circle class='active' r="12" cx="13" cy="13" fill='transparent' stroke='black' stroke-opacity='0.8' stroke-width='2px'></circle>
      </svg>
    </div>
  `
}

export default getTimerTemplate
