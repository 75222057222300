import Swiper from 'swiper'
import { Manipulation } from 'swiper/modules'

import { noop as _noop } from 'lodash'

const MIN_SCALE_SIZE = 0.7

export class Carousel {
  swiper: Swiper
  block: Element
  startPoint: number = 0
  width: number = 0
  onItemUpdated: (swiper: Swiper) => void

  onItemClick: (swiper: Swiper) => void

  constructor({ block, slideChange, onItemClick = _noop }) {
    this.block = block
    this.onItemUpdated = slideChange
    this.onItemClick = onItemClick
  }

  init() {
    const element: HTMLElement = this.block.querySelector('.swiper')
    this.swiper = new Swiper(element, {
      modules: [Manipulation],
      slideActiveClass: 'carousel__item--active',
      speed: 200,
      // lazy: true,
      slidesPerView: 1.3,
      spaceBetween: 0,
      centeredSlides: true
    })
    this.swiper.on('sliderMove', this.sliderMove)
    this.swiper.on('touchStart', this.touchStart)
    this.swiper.on('slideResetTransitionStart', this.slideResetTransitionStart)
    this.swiper.on('slideChangeTransitionEnd', this.slideChangeTransitionEnd)
    this.swiper.on('slideChange', this.onSlideChange)
    this.swiper.on('click', this.onItemClick)
  }

  onSlideChange = (swiper: Swiper) => {
    this.onItemUpdated(swiper)
  }

  slideChangeTransitionEnd = (swiper: Swiper) => {
    this.updatedActiveItemScale(swiper.slides[swiper.activeIndex], 0)
    const nextSlide = swiper.slides[swiper.activeIndex + 1]
    const prevSlide = swiper.slides[swiper.activeIndex - 1]

    if (nextSlide) {
      this.updatedActiveItemScale(nextSlide, 100)
    }
    if (prevSlide) {
      this.updatedActiveItemScale(prevSlide, 100)
    }
  }

  updatedActiveItemScale = (element, progress: number) => {
    requestAnimationFrame(() => {
      const scaleSize = (100 - Math.abs(progress / 1.5)) / 100
      const recommendedScaleSize = scaleSize < MIN_SCALE_SIZE ? MIN_SCALE_SIZE : scaleSize
      const opacity = progress === 0 ? 1 : Math.abs((100 - Math.abs(progress * 1.5)) / 100)
      element.style.transform = `scale(${recommendedScaleSize})`
      element.style.setProperty('--opacity', opacity)
    })
  }

  sliderMove = (swiper: Swiper) => {
    const progress = ((swiper.translate - this.startPoint) / this.width) * 100
    const nextSlide = swiper.slides[swiper.activeIndex + 1]
    const prevSlide = swiper.slides[swiper.activeIndex - 1]

    if (progress > 0 && prevSlide) {
      this.updatedNeighborItemScale(prevSlide, progress)
    } else if (nextSlide) {
      this.updatedNeighborItemScale(nextSlide, progress)
    }

    this.updatedActiveItemScale(swiper.slides[swiper.activeIndex], progress)
  }

  updatedNeighborItemScale = (element, progress: number) => {
    requestAnimationFrame(() => {
      const scaleSize = (MIN_SCALE_SIZE * 100 + Math.abs(progress)) / 100
      const recommendedScaleSize = scaleSize > 1 ? 1 : scaleSize
      element.style.transform = `scale(${recommendedScaleSize})`
    })
  }

  touchStart = (swiper: Swiper) => {
    this.startPoint = swiper.translate
    this.width = swiper.width
  }

  slideResetTransitionStart = (data: any) => {
    this.updatedActiveItemScale(data.slides[data.activeIndex], 0)
    const nextSlide = data.slides[data.activeIndex + 1]
    const prevSlide = data.slides[data.activeIndex - 1]

    if (nextSlide) {
      this.updatedActiveItemScale(nextSlide, 100)
    }

    if (prevSlide) {
      this.updatedActiveItemScale(prevSlide, 100)
    }
  }
}
