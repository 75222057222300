import getTimerTemplate from '../shared/templates/layouts/timer'

class Timer {
  time: number = 0
  container: HTMLElement = null
  timerEl: HTMLElement = null
  interval = null
  activeCircle: HTMLElement = null
  constructor({ container, time }) {
    this.time = time
    this.container = container

    this.init()
  }

  init() {
    this.container.innerHTML = getTimerTemplate()
    this.timerEl = this.container.querySelector('.timer')
    this.activeCircle = this.container.querySelector('.active')

    this.timerEl.style.setProperty('--time', `${this.time}s`)
    this.start()
  }

  reset() {
    clearInterval(this.interval)
    this.activeCircle.classList.add('active--reset')
    setTimeout(() => {
      this.activeCircle.classList.remove('active--reset')
    }, 300)

    this.timerEl.style.setProperty('--time', `${this.time}s`)
    this.timerEl.style.setProperty('opacity', '1')
    this.start()
  }

  start() {
    const countdownNumberEl = this.container.getElementsByClassName('timer-number')[0]
    let countdown = this.time
    countdownNumberEl.textContent = `${countdown}`

    this.interval = setInterval(() => {
      countdown = --countdown

      if (countdown <= 0) {
        this.destroy()
      }

      countdownNumberEl.textContent = `${countdown}`
    }, 1000)
  }

  destroy() {
    this.timerEl.dispatchEvent(new CustomEvent('timer-end'))
    clearInterval(this.interval)
    this.timerEl.style.setProperty('--time', `${0}s`)
    this.timerEl.style.setProperty('opacity', '0')
  }
}

export default Timer
