import jokesEn from './jokes_en'
import jokesEs from './jokes_es'
import jokesPt from './jokes_pt'
import jokesHi from './jokes_hi'
import jokesIt from './jokes_it'
import jokesEs_mx from './jokes_es'
import jokesDe_at from './jokes_de_at'
import jokesEn_in from './jokes_en'
import jokesEn_tn from './jokes_en_tn'
import jokesEn_it from './jokes_en_it'
import jokesFr_ca from './jokes_fr_ca'
import jokesEn_ph from './jokes_en_ph'
import jokesEn_at from './jokes_en_at'
import jokesEn_ae from './jokes_en_ae'

export default {
  en: jokesEn,
  es: jokesEs,
  pt: jokesPt,
  hi: jokesHi,
  it: jokesIt,
  'es-mx': jokesEs_mx,
  'de-at': jokesDe_at,
  'en-in': jokesEn_in,
  'en-tn': jokesEn_tn,
  'en-it': jokesEn_it,
  'fr-ca': jokesFr_ca,
  'en-ph': jokesEn_ph,
  'en-at': jokesEn_at,
  'en-ae': jokesEn_ae
}
