export default [
  {
    setup: 'Como se chama alguém sem nada abaixo dos tornozelos e sem nada acima do pescoço?',
    punchline: 'Sem pé nem cabeça.'
  },
  {
    setup: 'Qual é a língua menos falada no mundo?',
    punchline: 'Linguagem de sinais'
  },
  {
    setup: 'Por que o homem invisível recusou a oferta de emprego?',
    punchline: 'Ele não conseguia se ver naquele cargo.'
  },
  {
    setup: 'Por que o pirata usava um tapa-olho?',
    punchline: 'Porque esqueceu que tinha o gancho na mão quando foi lavar o rosto.'
  },
  {
    setup: 'Por que os elefantes são enrugados?',
    punchline: 'Porque eles não cabem na tábua de passar roupa.'
  },
  {
    setup: 'Por que o homem era atropelado por uma bicicleta todo dia?',
    punchline: 'Ele estava preso em um ciclo vicioso.'
  },
  {
    setup: 'Por que o contorcionista estava sempre tão cansado?',
    punchline: 'Porque ele trabalhava dobrado.'
  },
  {
    setup: 'Qual idade é a mais difícil de encarar?',
    punchline: 'Realidade.'
  },
  {
    setup: 'O que um chapéu disse para o outro?',
    punchline: 'Guarda a vaga aqui. Eu vou cair de cabeça.'
  },

  {
    setup: 'Descanse em paz agua fervente...',
    punchline: 'Sua alma está no ar!'
  },

  {
    setup: 'Porque a academia de ginástica perdeu todos os clientes?',
    punchline: 'O preço ficou pesado.'
  },

  {
    setup: 'Já tentou comer um relógio?',
    punchline: 'Leva bastante tempo.'
  },

  {
    setup: 'Que nome se dá para um cachorro mágico?',
    punchline: 'Um labracadabrador'
  },

  {
    setup: 'Eu inventei uma palavra nova!',
    punchline: 'Plágio!'
  },

  {
    setup: 'Por que o contorcionista estava sempre tão cansado?',
    punchline: 'Porque ele trabalhava dobrado.'
  },

  {
    setup: 'Você ouviu a história do ator que afundou no assoalho do palco?',
    punchline: 'O nível dele estava caindo.'
  },

  {
    setup: 'Já ouviu sobre o novo restaurante chamado Karma?',
    punchline: 'Não tem menu. Você é o servido o que você merece.'
  },
  {
    setup: 'Uma mulher dando a luz gritou repentinamente, “Daqui! Daí! Dum! Duma! Doutra!”',
    punchline: '“Não se preocupe,” diz o médico. “São apenas contrações.”'
  },
  {
    setup: 'Por que cientistas não confiam em átomos?',
    punchline: 'Porque eles criam tudo quanto é coisa.'
  },
  {
    setup: 'O que dá choque e tem tiques nervosos?',
    punchline: 'Uma pilha de nervos.'
  },
  {
    setup: 'O que uma pimenta intrometida faz?',
    punchline: 'Revira sua mala-gueta!'
  },
  {
    setup: 'Por que é difícil explicar trocadilhos para cleptomaníacos?',
    punchline: 'Eles sempre levam as coisas, literalmente.'
  },
  {
    setup: 'Um homem diz para seu médico, “Doutor, me ajude. Estou viciado no Twitter!”',
    punchline: 'O médico responde, “Desculpe, não estou te acompanhando...”'
  },
  {
    setup: 'Que tipo de exercício as pessoas preguiçosas fazem?',
    punchline: 'Espreguiçamento.'
  },
  {
    setup: 'O que o olho esquerdo disse para o olho direito?',
    punchline: 'Aqui entre nós, tem algo cheirando.'
  },
  {
    setup: 'O que um chapéu disse para o outro?',
    punchline: 'Guarda a vaga aqui. Eu vou cair de cabeça.'
  },
  {
    setup: 'O que o tubarão disse quando comeu o peixe-palhaço?',
    punchline: 'Tem um gosto engraçado.'
  },
  {
    setup: ' Por que o M&M foi ao leilão?',
    punchline: 'Para dar um Lancy.'
  },
  {
    setup: 'Descanse em paz agua fervente.',
    punchline: 'Sua alma está no ar.'
  },
  {
    setup: 'Por que o livro de matemática procurou orientação de um conselheiro?',
    punchline: 'Ele precisava de ajuda com um problema.'
  },
  {
    setup: 'Por que formigas alegres não afundam na água?',
    punchline: 'Porque levam a vida de maneira leve.'
  },
  {
    setup: 'Como chamamos uma cobra que não tem nenhuma obra?',
    punchline: 'Um C.'
  },
  {
    setup: 'Dois peixinhos dourados estão num aquário.',
    punchline: 'Um olha para o outro e diz, “Você sabe pilotar essa coisa?!”'
  },
  {
    setup: 'Como espantalho, as pessoas dizem que eu me destaco no meu campo.',
    punchline: 'É um dom que está nas fibras do meu ser.'
  },
  {
    setup: 'Fiquei acordado a noite inteira tentando descobrir onde estava o Sol.',
    punchline: 'De manhã me bateu uma luz.'
  },

  {
    setup: 'Lembre-se, trabalho em equipe é importante.',
    punchline: 'Ajuda na hora de colocar a culpa em outra pessoa.'
  },

  {
    setup: 'Acabei de ouvir que a empresa vai demitir o funcionário com a pior postura.',
    punchline: 'Não sei que é, mas estou inclinado a pensar em alguém.'
  },

  {
    setup: 'Fui demitido da fábrica de calendários.',
    punchline: 'Não sei por que, eu só perdi um dia.'
  },

  {
    setup: 'Quer ouvir uma piada sobre um animal barulhento?',
    punchline: 'Deixa pra lá, não soa muito bem.'
  },

  {
    setup: 'Quer ouvir uma piada sobre construções?',
    punchline: 'Te conto depois, ainda estou trabalhando nela.'
  },

  {
    setup: 'O que você pode manter sem nunca precisar tocar?',
    punchline: 'Uma conversa.'
  },

  {
    setup: 'Quantos lados tem um círculo?',
    punchline: 'Dois. O de dentro e o de fora.'
  },

  {
    setup: 'O que consegue responder sua pergunta em qualquer idioma?',
    punchline: 'Um eco.'
  },
  {
    setup: 'O que tem uma coroa e um cara, mas nenhuma cabeça pra colocar as duas?',
    punchline: 'Uma moeda.'
  },
  {
    setup: 'O que fica no canto o tempo todo, e ainda assim viaja mundo afora?',
    punchline: 'Um selo.'
  },
  {
    setup: 'O que tem três saídas e apenas uma entrada?',
    punchline: 'Uma camiseta.'
  },
  {
    setup: 'Por que os ângulos obtusos estão sempre tristes?',
    punchline: 'Porque não conseguem andar reto.'
  },
  {
    setup: 'Que animal tem aproximadamente 3.14 olhos?',
    punchline: 'O pi-olho!'
  },
  {
    setup: 'O que vai embora ao mesmo tempo que acontece no estádio de futebol?',
    punchline: 'Uma partida.'
  },
  {
    setup: 'O que o pescador disse para o mágico?',
    punchline: 'Escolhe uma carpa, qualquer carpa!'
  },
  {
    setup: 'Monstros são bons em matemática?',
    punchline: 'Não, a menos que você Conte Drácula.'
  },
  {
    setup: 'Por que os peixes são tão rápidos?',
    punchline: 'Porque eles vivem em car-dumes!'
  },
  {
    setup: 'Que raça de cachorro consegue saltar mais alto que prédios?',
    punchline: 'Qualquer uma, porque prédios não saltam.'
  },
  {
    setup: 'O que a flor mais velha disse para paquerar a flor mais nova?',
    punchline: 'Oi, Broto!'
  },
  {
    setup: 'Por que o cabelo das abelhas é pegajoso?',
    punchline: 'Porque elas usam cera ao invés de gel.'
  },
  {
    setup: 'Por que o peru pediu o divórcio?',
    punchline: 'Ele descobriu que a perua era uma galinha.'
  },
  {
    setup: 'Qual é a melhor coisa sobre a Suíça?',
    punchline: 'Não sei, mas a bandeira é um sinal positivo.'
  },
  {
    setup: 'Você ouviu a história do ator que afundou no assoalho do palco?',
    punchline: 'O nível dele estava caindo.'
  },
  {
    setup: 'Você ouviu sobre o astronauta claustrofóbico?',
    punchline: 'Ele só precisava de um pouco de espaço.'
  },
  {
    setup: 'Por que cientistas não confiam em átomos?',
    punchline: 'Para chegar do outro lado.'
  },
  {
    setup: 'Por que o semáforo ficou vermelho?',
    punchline: 'Ele ficou com vergonha por estar se trocando no meio da rua!'
  },

  {
    setup: 'Por que piratas têm inveja de marujos?',
    punchline: 'Porque os marujos tem o “mar” no nome.'
  },

  {
    setup: 'O que o 0 disse para o 8?',
    punchline: '”Bonito seu cinto.”'
  },

  {
    setup: 'Por que alguns peixes vivem em água salgada?',
    punchline: 'Porque se vivessem em água apimentada, espirrariam.'
  },

  {
    setup: 'Por que a criança jogou o relógio pela janela?',
    punchline: 'Porque queria ver o tempo voar.'
  },

  {
    setup: 'Onde ursos polares acumulam dinheiro?',
    punchline: 'Em montanhas de neve!'
  },

  {
    setup: 'Como chamamos uma cobra que não tem nenhuma obra?',
    punchline: 'Um “C”.'
  },

  {
    setup: 'Como se chama o queijo que é meu e não seu?',
    punchline: 'Meussarella!'
  },

  {
    setup: 'Por que a foto foi para a prisão?',
    punchline: 'Ela foi enquadrada.'
  },
  {
    setup: 'Qual é o filme preferido das vacas?',
    punchline: 'A Múuu-mia.'
  },
  {
    setup: 'O que um olho disse para o outro olho?',
    punchline: 'Aqui entre nós, tem algo cheirando.'
  },
  {
    setup: 'O que é uma gaivota com as cores do arco-íris?',
    punchline: 'Uma gayvota.'
  },
  {
    setup: 'Como o touro e a vaca chamam seu filho mais novo?',
    punchline: 'Bebê-zerro!'
  },
  {
    setup: 'Por que o biscoito foi ao hospital?',
    punchline: 'Ele estava se sentindo esmigalhado.'
  },
  {
    setup: 'Que tipo de sala não tem portas?',
    punchline: 'Uma salamandra.'
  },
  {
    setup: 'Qual das mãos é melhor para escrever?',
    punchline: 'Nenhuma. É melhor escrever com uma caneta.'
  },
  {
    setup: 'Que outros animais você sempre vai encontrar em corridas de cavalos?',
    punchline: 'Patas!'
  },
  {
    setup: 'O que um filme faz quando vê seus amigos?',
    punchline: 'Ele acena!'
  },
  {
    setup: 'O que um boneco de neve disse para o outro boneco de neve?',
    punchline: 'Sinto o cheiro de cenouras por aqui!'
  },
  {
    setup: 'Porque o jogador de golfe usa duas calças?',
    punchline: 'Por segurança, caso tenha um buraco em uma!'
  },
  {
    setup: 'O que tem quatro rodas e moscas?',
    punchline: 'Um caminhão de lixo.'
  },
  {
    setup: 'O que a pia disse para o bidê?',
    punchline: 'Brincando de chafariz de novo?'
  },
  {
    setup: 'Que animal consegue levantar carros e descascar bananas?',
    punchline: 'O macaco.'
  },
  {
    setup: 'Qual a diferença entre um violão e um ladrão?',
    punchline: 'O violão afina, o ladrão afana.'
  },
  {
    setup: 'O que o semáforo disse para o carro?',
    punchline: 'Vira de costas. Preciso me trocar.'
  },
  {
    setup: 'O que fica mais molhada quanto mais seca?',
    punchline: 'Uma toalha.'
  },
  {
    setup: 'Por que a vassoura foi internada no hospício?',
    punchline: 'Ela era uma doida varrida.'
  },

  {
    setup: 'Por que a bexiga não comeu o bolo na festa de aniversário?',
    punchline: 'Porque ela já estava cheia!'
  },

  {
    setup: 'Como se chama um macarrão com molho falsificado?',
    punchline: 'Charlatão ao sugo!'
  },

  {
    setup: 'Por que o aluno cozinhou lentamente o trabalho escolar?',
    punchline: 'Porque a professora disse que o apressado come cru!'
  },

  {
    setup: 'Por que o aluno cozinhou lentamente o trabalho escolar?',
    punchline: 'Porque a professora disse que o apressado come cru!'
  },

  {
    setup: 'Qual e a tecla predileta dos astronautas em teclados de computador?',
    punchline: 'A barra de espaço!'
  },

  {
    setup: 'Por que os fantasmas não conseguem mentir?',
    punchline: 'Porque são muito transparentes!'
  },

  {
    setup: 'Por que os fantasmas não conseguem mentir?',
    punchline: 'Porque são muito transparentes!'
  },

  {
    setup: 'Como se chama o medico que cuida da visão dos dinossauros?',
    punchline: 'Um oculistossauro!'
  },
  {
    setup: 'Que urso é generoso e gosta de distribuir alimentos?',
    punchline: 'O pão-da!'
  },
  {
    setup: 'Como atrair a atençã de uma mulher da roça?',
    punchline: 'Dirija um a-trator.'
  },
  {
    setup: 'CQue trabalho o Exterminador do Futuro faria depois de se aposentar?',
    punchline: 'Dedetizador do Presente.'
  },
  {
    setup: 'Por que alguns casais vão juntos para a academia?',
    punchline: 'Para fortalecer a relação.'
  },
  {
    setup: 'Qual o oceano que mais detesta guerras?',
    punchline: 'O Pacífico.'
  },
  {
    setup: 'Que tipo de munição precisa ser mastigada?',
    punchline: 'A bala de goma.'
  },
  {
    setup: 'Que nome os dentistas dão para suas chapas de raios-x?',
    punchline: 'Selfie bucal.'
  },
  {
    setup: 'Que nome se dá para uma vaca desidratada?',
    punchline: 'Carne seca. '
  },
  {
    setup: 'Qual era a fruta favorita do Beethoven?',
    punchline: 'BA-NA-NA-NAAAA'
  },
  {
    setup: 'Por que o livro de matemática estava triste?',
    punchline: 'Ele tinha muitos problemas.'
  },
  {
    setup: 'Por que o homem caiu no poço?',
    punchline: 'Porque ele era profundamente cego.'
  },

  {
    setup: 'Quando uma piada se torna uma “piada de pai”?',
    punchline: 'Quando se torna a-parente.'
  },

  {
    setup: 'O que a flores fazem na noite de núpcias depois do casamento?',
    punchline: 'Ficam rosadas.'
  },

  {
    setup: 'Qual é o calçado preferido dos padeiros?',
    punchline: 'A pão-tufa.'
  },

  {
    setup: 'Por que alguns melões são vermelhos por dentro?',
    punchline: 'Eles não são. Aquelas são melancias.'
  },

  {
    setup: 'Que nome o baterista deu para suas duas filhas gêmeas?',
    punchline: 'Ana Um, Ana Dois!'
  },

  {
    setup: 'O que uma pimenta intrometida faz?',
    punchline: 'Revira sua mala-gueta.'
  },

  {
    setup: 'O que as casas vestem?',
    punchline: 'En-dereços.'
  },
  {
    setup: 'Porque o espantalho ganhou um prêmio?',
    punchline: 'Ele se destacou no seu campo.'
  },
  {
    setup: 'Que estado dos EUA é conhecido por seus refrigerantes especialmente pequenos?',
    punchline: 'Minnesota.'
  },
  {
    setup: 'Como os corredores comem antes de uma corrida?',
    punchline: 'Rapidinho.'
  },
  {
    setup: 'Como um gato exagerado se chama?',
    punchline: 'Um leão.'
  },
  {
    setup: 'Por que você não deve escrever com um lápis sem ponta? ',
    punchline: 'Para não ficar desapontado.'
  },
  {
    setup: 'Qual é o esporte mais patriótico?',
    punchline: 'Futebol de Bandeira.'
  },
  {
    setup: 'Quer ouviu uma piada sobre uma bexiga cheia de hélio?',
    punchline: 'Ah, droga. Escapou e fugiu!'
  },
  {
    setup: 'Qual o melhor nome para um homem que não se fecha em isolamento?',
    punchline: 'Alberto'
  },
  {
    setup: 'O que dá choque e tem tiques nervosos?',
    punchline: 'Uma pilha de nervos.'
  },
  {
    setup: 'Qual é a pior parte de ficar enjoado em alto mar?',
    punchline: 'Vem em ondas.'
  },
  {
    setup: 'Por que a banana resolveu se casar?',
    punchline: 'Ela estava amadurecendo.'
  },
  {
    setup: 'Por que os pintinhos foram ao show de stand-up?',
    punchline: 'Para ouvir as piadas.'
  },
  {
    setup: 'Por que o Papai Noel fez aulas de dança?',
    punchline: 'Para aprender a embalar melhor.'
  },
  {
    setup: 'O que o filme disse para o cinema?',
    punchline: 'Nada, ele apenas acenou.'
  },
  {
    setup: 'Por que a impressora teve que mostrar seu distintivo?',
    punchline: 'Ela estava prendendo o papel de novo.'
  },
  {
    setup: 'Quando você acelera no vermelho e para no verde?',
    punchline: 'Quando está comendo uma melancia.'
  },
  {
    setup: 'Como a vaca se sente depois de um longo dia de trabalho pesado?',
    punchline: 'Como carne moída.'
  },
  {
    setup: 'Que tipo de roupa as nuvens preferem?',
    punchline: 'Esvoaçantes e sem forma.'
  },

  {
    setup: 'Que nome se dá para uma coruja que sabe fazer mágica?',
    punchline: 'Huu-dini'
  },

  {
    setup: 'Que nome se dá para um grupo de baleias assassinas tocando instrumentos?',
    punchline: 'Uma orca-estra'
  },

  {
    setup: 'Como você encontra o Will Smith na neve?',
    punchline: 'Você procura por Um Maluco no Pedaço.'
  },

  {
    setup: 'Qual a diferença entre um hipopótamo e sua própria sombra?',
    punchline: 'Um deles não te esmaga se cair sobre você.'
  },

  {
    setup: 'Que marca um vampiro universitário gosta de comprar?',
    punchline: 'Forever 21'
  },

  {
    setup: 'O que o cavalo diz depois de tropeçar e cair?',
    punchline: 'Socorro! Acho que atropelei umas patas!'
  },

  {
    setup: 'Porque a mulher não ficou feliz com o preço do velcro?',
    punchline: 'A promoção não colou.'
  },

  {
    setup: 'O que você tem se cruzar um vampiro com um boneco de neve?',
    punchline: 'Mordidas geladas.'
  },
  {
    setup: 'O que uma pimenta irritada faz?',
    punchline: 'Te deixa vermelho e chorando.'
  },
  {
    setup: 'Quer ouvir uma piada sobre fita adesiva?',
    punchline: 'Deixa pra lá. Não vai colar.'
  },
  {
    setup: 'Como 2019 resolveu seus problemas com 2020?',
    punchline: 'Prorrogaram para 2021.'
  },
  {
    setup: 'Por que os pinguins mais gordos são mais populares em festas?',
    punchline: 'Eles conseguem quebrar o gelo.'
  },
  {
    setup: 'O que o esquiador disse para seu terapeuta?',
    punchline: 'Minha vida está indo ladeira abaixo.'
  },
  {
    setup: 'Qual é a letra mais fria do alfabeto?',
    punchline: 'B — ele fica entre o AC.'
  },
  {
    setup: 'Por que você não deve ficar bravo com pessoas preguiçosas?',
    punchline: 'Elas não fizeram nada.'
  },
  {
    setup: 'Que pássaro tem uma loja que só vende coisas roxas?',
    punchline: 'O Rouxinol.'
  },
  {
    setup: 'O que a girafa disse antes de ser atacada por um gato selvagem?',
    punchline: 'Calma lá, tigrão!'
  },
  {
    setup: 'Quando um piloto deve se aposentar?',
    punchline: 'Quando começar a ficar com a cabeça nas nuvens.'
  },
  {
    setup: 'O que pousa em uma árvore e grita "Aaaargh"?',
    punchline: 'Uma coruja com problemas de dicção.'
  },
  {
    setup: 'O que o lenhador disse para a árvore falante que implorava para não ser cortada?',
    punchline: 'Não me lenha com desculpas!!'
  }
]
