export default [
  { setup: 'दुनिया में सबसे कम बोली जाने वाली भाषा कौन सी है?', punchline: 'संकेत की भाषा' },
  {
    setup: 'अदृश्य आदमी ने नौकरी की पेशकश क्यों ठुकरा दी?',
    punchline: 'क्योंकि वह खुद को काम करते हुए नहीं देख सकता था।'
  },
  {
    setup: 'हाथी को झुर्रियां क्यूँ होती है?',
    punchline: 'क्योंकि आप उनकी इस्त्री नहीं कर सकते।'
  },
  {
    setup: 'पुलिसवाले ने अपनी नाभि से क्या कहा?',
    punchline: 'यू आर अंडर अ वेस्ट! '
  },
  {
    setup: 'आदमी हर दिन बाइक से क्यों टकराता था?',
    punchline: 'वह किसी चक्कर में फंस गया था।'
  },
  {
    setup: 'समुद्री राक्षस क्या खाते हैं?',
    punchline: 'फ़िश एंड शिप्स। '
  },
  {
    setup: 'कौन सी टी को निगलना मुश्किल है?',
    punchline: 'रियेलि-टी। '
  },
  {
    setup: 'नाई ने कैसे दौड़ जीती?',
    punchline: 'वह एक शार्ट-कट जानता था। '
  },
  {
    setup: 'मेंढक इतने खुश क्यों होते हैं?',
    punchline: 'जो कोई उन्हें बग करता है वे उसे खा जाते हैं।'
  },
  {
    setup: 'एक टोपी ने दूसरी को क्या कहा?',
    punchline: 'यू स्टे हियर. आइ विल गो ऑन अ-हेड.'
  },
  {
    setup: 'जिम क्यों बंद हो गया?',
    punchline: 'क्यूंकी वो वर्क आउट नहीं करता था।'
  },
  {
    setup: 'फॉरेस्ट गम्प का पासवर्ड क्या है?',
    punchline: 'वनफोरेस्टवन'
  },
  {
    setup: 'एक जादुई कुत्ते को आप क्या कहेंगे?',
    punchline: 'लेब्राकडाब्राडोर '
  },
  {
    setup: 'मैंने एक नया शब्द ईजाद किया!',
    punchline: 'प्लेजियारिज़्म!'
  },
  {
    setup: 'क्या आपने उस गणितज्ञ के बारे में सुना है जो नकारात्मक संख्या से डरता है?',
    punchline: 'वो उनसे बचने के लिए शून्य तक चला जाता है।'
  },
  {
    setup: 'हेल्वेटिका और टाइम्स न्यू रोमन एक बार में जाते हैं।',
    punchline:
      'यहाँ से चले जाओ! बारटेंडर चिल्लाता है। "हम तुम्हारे टाइप के लोगों को सर्व नहीं करते।"'
  },
  {
    setup: 'क्या आपने उस अभिनेता के बारे में सुना है जो फ़्लोरबोर्ड से नीचे गिर गया था?',
    punchline: 'वो बस एक स्टेज से गुज़र रहा था। '
  },
  {
    setup: 'कर्मा नामक नए रेस्तरां के बारे में सुना?',
    punchline: 'वहाँ कोई मेन्यू नहीं है। कर्मों के अनुसार ही फल मिलता है।'
  },
  {
    setup: 'वैज्ञानिकों को परमाणुओं पर भरोसा क्यों नहीं है?',
    punchline: 'क्योंकि वो सबको बनाते हैं।'
  },
  {
    setup: 'औसत चीजों का निर्माण कहाँ होता है?',
    punchline: 'सैटिस-फ़ैक्टरी में'
  },
  {
    setup: 'कौन सी टी को निगलना मुश्किल है?',
    punchline: 'क्यूंकी वे चीजों को सच में ले लेते हैं।'
  },
  {
    setup: 'एक आदमी अपने डॉक्टर से कहता है, "डॉक्टर, मेरी मदद करो। मैं ट्विटर का आदी हूँ!"',
    punchline: 'डॉक्टर जवाब देता है, "क्षमा करें, मैं आपको फॉलो नहीं करता..."'
  },
  {
    setup: 'आलसी लोग किस तरह का व्यायाम करते हैं?',
    punchline: 'डिडली-स्क्वाट्स'
  },
  {
    setup: 'चार्ल्स डिकेंस अपने मसाले के डब्बे में क्या रखता है?',
    punchline: 'द बेस्ट ऑफ थाइम्स, द वर्स्ट ऑफ थाइम्स।'
  },
  {
    setup: 'तोहफे में एक कंघी प्राप्त करने पर गंजे आदमी ने क्या कहा?',
    punchline: 'धन्यवाद— मैं कभी इससे पार्ट नहीं करूंगा!'
  },
  {
    setup: 'बाईं आंख ने दाईं आंख को क्या कहा?',
    punchline: 'तुम्हारे और मेरे बीच में कुछ महक रहा है। '
  },
  {
    setup: 'एक टोपी ने दूसरी से क्या कहा?',
    punchline: 'यू स्टे हियर. आइ विल गो ऑन अ-हेड.'
  },
  {
    setup: 'शार्क ने जब एक क्लाउनफ़िश खाया तो उसने क्या कहा?',
    punchline: 'इसका स्वाद बड़ा मज़ाकिया है।'
  },
  {
    setup: 'दही कला-प्रदर्शनी में क्यों गया?',
    punchline: 'क्यूंकी वो बहुत कलचर्ड था।  '
  },
  {
    setup: 'ओरियो डेंटिस्ट के पास क्यों गया?',
    punchline: 'क्यूंकी उसने अपनी फिल्लिंग खो दी थी। '
  },
  {
    setup: 'बड़े लाड़-प्यार से पाली गाय से आपको क्या मिलता है?',
    punchline: 'बिगड़ा हुआ दूध। '
  },
  {
    setup: 'नर्स को काम करते समय लाल कलम की आवश्यकता क्यों पड़ी? ',
    punchline: 'उसे खून ड्रॉ करने की जरूरत थी।'
  },
  {
    setup: 'गणित की किताब गाइड के पास क्यों गयी?',
    punchline: 'उसे अपनी समस्याओं का हल करने में मदद की जरूरत थी।'
  },
  {
    setup: 'नर्स को काम करते समय लाल कलम की आवश्यकता क्यों पड़ी? ',
    punchline: 'उसे खून ड्रॉ करने की जरूरत थी।             '
  },
  {
    setup: 'गणित की किताब गाइड के पास क्यों गयी?',
    punchline: 'उसे अपनी समस्याओं का हल करने में मदद की जरूरत थी।'
  },
  {
    setup: 'नर चींटियां क्यों नहीं डूबतीं? ',
    punchline: 'क्यूंकी वे बॉय-एंट होती हैं।'
  },
  {
    setup: 'दो गोल्डफ़िश एक टैंक में।',
    punchline: 'पहली दूसरी को देखती है और कहती है, "क्या तुम इस चीज़ को चलाना जानते हो?"'
  },
  {
    setup: 'अलेक्ज़ेंडर द ग्रेट और विनी द पूह में क्या समानता है?',
    punchline: 'उनका मिडिल नेम।'
  },
  {
    setup: 'याद रखें, टीम वर्क महत्वपूर्ण है।',
    punchline: 'यह किसी और पर दोष मढ़ने में मदद करता है।'
  },
  {
    setup: 'मुझे कैलेंडर फैक्ट्री से निकाल दिया गया।',
    punchline: 'पता नहीं क्यों - मैंने तो बस एक दिन ही गोल किया था।'
  },
  {
    setup: 'क्या आप एक कंस्ट्रक्शन जोक सुनना चाहते हैं?',
    punchline: 'बाद में बताऊंगा - अभी कार्य प्रगति पर है।'
  },
  {
    setup: 'वो क्या है जिसे आप कभी बिना छुए भी जारी रख सकते हैं?',
    punchline: 'बातचीत।'
  },
  {
    setup: 'एक गोले में कितनी साइड होती हैं?',
    punchline: 'दो। इंसाइड और आउटसाइड।              '
  },
  {
    setup: 'वो क्या है जिसके एक हेड और एक टेल होती है, लेकिन कोई शरीर नहीं है?',
    punchline: 'सिक्का।'
  },
  {
    setup: 'हाथी को झुर्रियां क्यूँ होती है?',
    punchline: 'क्योंकि आप उनकी इस्त्री नहीं कर सकते।'
  },
  {
    setup: 'पुलिसवाले ने अपनी नाभि से क्या कहा?',
    punchline: 'यू आर अंडर अ वेस्ट! '
  },
  {
    setup: 'आदमी हर दिन बाइक से क्यों टकराता था?',
    punchline: 'वह किसी चक्कर में फंस गया था।'
  },
  {
    setup: 'समुद्री राक्षस क्या खाते हैं?',
    punchline: 'फ़िश एंड शिप्स। '
  },
  {
    setup: 'कौन सी टी को निगलना मुश्किल है?',
    punchline: 'रियेलि-टी। '
  },
  {
    setup: 'नाई ने कैसे दौड़ जीती?',
    punchline: 'वह एक शार्ट-कट जानता था। '
  },
  {
    setup: 'मेंढक इतने खुश क्यों होते हैं?',
    punchline: 'जो कोई उन्हें बग करता है वे उसे खा जाते हैं।'
  },
  {
    setup: 'एक टोपी ने दूसरी को क्या कहा?',
    punchline: 'यू स्टे हियर. आइ विल गो ऑन अ-हेड.'
  },
  {
    setup: 'जिम क्यों बंद हो गया?',
    punchline: 'क्यूंकी वो वर्क आउट नहीं करता था।'
  },
  {
    setup: 'फॉरेस्ट गम्प का पासवर्ड क्या है?',
    punchline: 'वनफोरेस्टवन'
  },
  {
    setup: 'एक जादुई कुत्ते को आप क्या कहेंगे?',
    punchline: 'लेब्राकडाब्राडोर '
  },
  {
    setup: 'मैंने एक नया शब्द ईजाद किया!',
    punchline: 'प्लेजियारिज़्म!'
  },
  {
    setup: 'क्या आपने उस गणितज्ञ के बारे में सुना है जो नकारात्मक संख्या से डरता है?',
    punchline: 'वो उनसे बचने के लिए शून्य तक चला जाता है।'
  },
  {
    setup: 'हेल्वेटिका और टाइम्स न्यू रोमन एक बार में जाते हैं।',
    punchline:
      'यहाँ से चले जाओ! बारटेंडर चिल्लाता है। "हम तुम्हारे टाइप के लोगों को सर्व नहीं करते।"'
  },
  {
    setup: 'क्या आपने उस अभिनेता के बारे में सुना है जो फ़्लोरबोर्ड से नीचे गिर गया था?',
    punchline: 'वो बस एक स्टेज से गुज़र रहा था। '
  },
  {
    setup: 'कर्मा नामक नए रेस्तरां के बारे में सुना?',
    punchline: 'वहाँ कोई मेन्यू नहीं है। कर्मों के अनुसार ही फल मिलता है।'
  },
  {
    setup: 'वैज्ञानिकों को परमाणुओं पर भरोसा क्यों नहीं है?',
    punchline: 'क्योंकि वो सबको बनाते हैं।'
  },
  {
    setup: 'औसत चीजों का निर्माण कहाँ होता है?',
    punchline: 'सैटिस-फ़ैक्टरी में'
  },
  {
    setup: 'कौन सी टी को निगलना मुश्किल है?',
    punchline: 'क्यूंकी वे चीजों को सच में ले लेते हैं।'
  },
  {
    setup: 'एक आदमी अपने डॉक्टर से कहता है, "डॉक्टर, मेरी मदद करो। मैं ट्विटर का आदी हूँ!"',
    punchline: 'डॉक्टर जवाब देता है, "क्षमा करें, मैं आपको फॉलो नहीं करता..."'
  },
  {
    setup: 'आलसी लोग किस तरह का व्यायाम करते हैं?',
    punchline: 'डिडली-स्क्वाट्स'
  },
  {
    setup: 'चार्ल्स डिकेंस अपने मसाले के डब्बे में क्या रखता है?',
    punchline: 'द बेस्ट ऑफ थाइम्स, द वर्स्ट ऑफ थाइम्स।'
  },
  {
    setup: 'तोहफे में एक कंघी प्राप्त करने पर गंजे आदमी ने क्या कहा?',
    punchline: 'धन्यवाद— मैं कभी इससे पार्ट नहीं करूंगा!'
  },
  {
    setup: 'बाईं आंख ने दाईं आंख को क्या कहा?',
    punchline: 'तुम्हारे और मेरे बीच में कुछ महक रहा है। '
  },
  {
    setup: 'एक टोपी ने दूसरी से क्या कहा?',
    punchline: 'यू स्टे हियर. आइ विल गो ऑन अ-हेड.'
  },
  {
    setup: 'शार्क ने जब एक क्लाउनफ़िश खाया तो उसने क्या कहा?',
    punchline: 'इसका स्वाद बड़ा मज़ाकिया है।'
  },
  {
    setup: 'दही कला-प्रदर्शनी में क्यों गया?',
    punchline: 'क्यूंकी वो बहुत कलचर्ड था।  '
  },
  {
    setup: 'ओरियो डेंटिस्ट के पास क्यों गया?',
    punchline: 'क्यूंकी उसने अपनी फिल्लिंग खो दी थी। '
  },
  {
    setup: 'बड़े लाड़-प्यार से पाली गाय से आपको क्या मिलता है?',
    punchline: 'बिगड़ा हुआ दूध। '
  },
  {
    setup: 'नर्स को काम करते समय लाल कलम की आवश्यकता क्यों पड़ी? ',
    punchline: 'उसे खून ड्रॉ करने की जरूरत थी।'
  },
  {
    setup: 'गणित की किताब गाइड के पास क्यों गयी?',
    punchline: 'उसे अपनी समस्याओं का हल करने में मदद की जरूरत थी।'
  },
  {
    setup: 'नर्स को काम करते समय लाल कलम की आवश्यकता क्यों पड़ी? ',
    punchline: 'उसे खून ड्रॉ करने की जरूरत थी।             '
  },
  {
    setup: 'गणित की किताब गाइड के पास क्यों गयी?',
    punchline: 'उसे अपनी समस्याओं का हल करने में मदद की जरूरत थी।'
  },
  {
    setup: 'नर चींटियां क्यों नहीं डूबतीं? ',
    punchline: 'क्यूंकी वे बॉय-एंट होती हैं।'
  },
  {
    setup: 'दो गोल्डफ़िश एक टैंक में।',
    punchline: 'पहली दूसरी को देखती है और कहती है, "क्या तुम इस चीज़ को चलाना जानते हो?"'
  },
  {
    setup: 'अलेक्ज़ेंडर द ग्रेट और विनी द पूह में क्या समानता है?',
    punchline: 'उनका मिडिल नेम।'
  },
  {
    setup: 'याद रखें, टीम वर्क महत्वपूर्ण है।',
    punchline: 'यह किसी और पर दोष मढ़ने में मदद करता है।'
  },
  {
    setup: 'मुझे कैलेंडर फैक्ट्री से निकाल दिया गया।',
    punchline: 'पता नहीं क्यों - मैंने तो बस एक दिन ही गोल किया था।'
  },
  {
    setup: 'क्या आप एक कंस्ट्रक्शन जोक सुनना चाहते हैं?',
    punchline: 'बाद में बताऊंगा - अभी कार्य प्रगति पर है।'
  },
  {
    setup: 'वो क्या है जिसे आप कभी बिना छुए भी जारी रख सकते हैं?',
    punchline: 'बातचीत।'
  },
  {
    setup: 'एक गोले में कितनी साइड होती हैं?',
    punchline: 'दो। इंसाइड और आउटसाइड।              '
  },
  {
    setup: 'वो क्या है जिसके एक हेड और एक टेल होती है, लेकिन कोई शरीर नहीं है?',
    punchline: 'सिक्का।'
  },
  {
    setup: 'वो क्या है जो हमेशा कोने में रहता है लेकिन दुनिया भर में यात्रा करता है?',
    punchline: 'स्टैंप। '
  },
  {
    setup: 'वो क्या है जिसमें जाने के तीन तरीके हैं और बाहर आने का सिर्फ एक ही रास्ता है?',
    punchline: 'यू आर अंडर अ वेस्ट! '
  },
  {
    setup: 'अधिक कोण इतने उदास क्यों होते हैं?',
    punchline: 'क्योंकि वे कभी राइट नहीं होते।             '
  },
  {
    setup: 'मछुआरे ने जादूगर से क्या कहा?',
    punchline: 'एक कॉड चुनो, कोई भी कॉड!'
  },
  {
    setup: 'कुत्तों की कौन सी नस्ल किसी इमारत से भी ऊँचा कूद सकती है?',
    punchline: 'कोई भी कुत्ता, क्योंकि इमारतें कूद नहीं सकतीं।'
  },
  {
    setup: 'बड़े फूल ने छोटे फूल से क्या कहा?',
    punchline: 'हाय, बड!'
  },
  {
    setup: 'मधुमक्खियों के बाल चिपचिपे क्यों होते हैं?',
    punchline: 'क्योंकि वे हनीकॉम्ब का उपयोग करती हैं।'
  },
  {
    setup: 'स्विट्जरलैंड के बारे में सबसे अच्छी बात क्या है?',
    punchline: 'मुझे नहीं पता, लेकिन उसका झंडा एक बड़ा प्लस है।'
  },
  {
    setup: 'क्या आपने उस गणितज्ञ के बारे में सुना है जो नकारात्मक संख्या से डरता है?',
    punchline: 'वो उनसे बचने के लिए शून्य तक चला जाता है। '
  },
  {
    setup: 'क्या आपने क्लॉस्ट्रोफ़ोबिक अंतरिक्ष यात्री के बारे में सुना है?',
    punchline: 'उसे बस थोड़ी सी जगह चाहिए थी।             '
  },
  {
    setup: 'वैज्ञानिकों को परमाणुओं पर भरोसा क्यों नहीं है?',
    punchline: 'क्योंकि वो सब को बनाते हैं।'
  },
  {
    setup: 'स्टॉपलाइट लाल क्यों हो गयी?',
    punchline: 'क्योंकि वो गली के बीचोबीच चेंज करने के कारण शर्मिंदा थी!             '
  },
  {
    setup: 'वर्णमाला सीखने में समुद्री डाकुओं को इतना समय क्यों लगता है?',
    punchline: 'क्योंकि वे कई साल C में बिताते हैं।'
  },
  {
    setup: 'बच्चे ने अपनी घड़ी खिड़की से बाहर क्यों फेंक दी?',
    punchline: 'क्योंकि वह समय को उड़ता हुआ देखना चाहता था।'
  },
  {
    setup: 'ध्रुवीय भालू अपना पैसा कहाँ रखते हैं?',
    punchline: 'एक स्नो बैंक में!'
  },
  {
    setup: 'श्रिम्प अपना ख़ज़ाना क्यों साझा नहीं करता?',
    punchline: 'क्योंकि वह थोड़ा शेलफिश था। '
  },
  {
    setup: 'सिंड्रेला फुटबॉल खेलने में उतनी अच्छी क्यों नहीं है?',
    punchline: 'क्योंकि वह हमेशा बॉल से दूर भागती रहती है!'
  },
  {
    setup: 'तस्वीर जेल क्यों गई? ',
    punchline: 'क्योंकि उसे फ्रेम किया गया था।             '
  },
  {
    setup: 'एक आँख ने दूसरी आँख को क्या कहा?',
    punchline: 'हम दोनों के बीच में कुछ महक रहा है।             '
  },
  {
    setup: 'साइकिलें गिर क्यूँ जाती हैं?',
    punchline: 'क्योंकि वे टू-टायर्ड हैं!'
  },
  {
    setup: 'किस रूम में दरवाजे नहीं होते?',
    punchline: 'मशरूम में।            '
  },
  {
    setup: 'किस हाथ से लिखना बेहतर है?',
    punchline: 'दोनों से नहीं, पेंसिल से लिखना बेहतर है। '
  },
  {
    setup: 'बेसबॉल खेल में हमेशा कौन सा जानवर होता है?',
    punchline: 'बैट!'
  },
  {
    setup: 'जब समुद्र अपने दोस्तों को देखता है तो वह क्या करता है?',
    punchline: 'वो वेव करता है!'
  },
  {
    setup: 'आप एक ऑक्टोपस को कैसे हंसाएंगे?',
    punchline: 'टेन-टिकल्स से। '
  },
  {
    setup: 'सिंक ने शौचालय को क्या कहा? ',
    punchline: 'तुम तो सच में फ़्लश्ड नज़र आ रहे हो!'
  },
  {
    setup: 'केले को किस तरह की की खोलती है?',
    punchline: 'मं-की (mon-key)। '
  },
  {
    setup: 'ट्रैफिक लाइट ने कार को क्या कहा?',
    punchline: 'उधर देखो, मैं चेंज करने वाला हूँ।'
  },
  {
    setup: 'वो क्या है जो जितना सुखाता है उतना अधिक गीला होता जाता है?',
    punchline: 'एक तौलिया।'
  },
  {
    setup: 'टेडी बियर ने मिठाई क्यों नहीं माँगी?',
    punchline: 'क्योंकि वह पहले से ही स्टफ़्ड था!'
  },
  {
    setup: 'एक हाथ 12 इंच लंबा क्यों नहीं हो सकता?',
    punchline: 'क्योंकि तब वह एक फ़ुट (foot) हो जायेगा!            '
  },
  {
    setup: 'छात्र ने अपना होमवर्क क्यों खा लिया? ',
    punchline: 'क्योंकि उसके शिक्षक ने कहा कि यह तो पीस ऑफ़ केक है!             '
  },
  {
    setup: 'भूत झूठ क्यों नहीं बोल पाते?',
    punchline: 'क्योंकि आप उनके आरपार देख सकते हैं!            '
  },
  {
    setup: 'किसी गाँव की लड़की का ध्यान किससे आकर्षित करें?',
    punchline: 'अ ट्रैक्टर।'
  },
  {
    setup: 'टर्मिनेटर को उनकी सेवानिवृत्ति के बाद क्या कहा जाएगा?',
    punchline: 'द एक्सटर्मिनेटर।            '
  },
  {
    setup: 'कुछ जोड़े जिम क्यों जाते हैं?',
    punchline: 'क्योंकि वे अपने रिश्ते को वर्क आउट करना चाहते हैं।             '
  },
  {
    setup: 'फॉरेस्ट गंप का पासवर्ड क्या है? ',
    punchline: 'वनफॉरेस्टवन '
  },
  {
    setup: 'आप बिना दाँत वाले भालू को क्या कहेंगे? ',
    punchline: 'गमी बेयर। '
  },
  {
    setup: 'डेंटिस्ट अपने एक्स-रे को क्या कहते हैं?',
    punchline: 'टूथ-पिक्स (pics)। '
  },
  {
    setup: 'बीथोवेन का पसंदीदा फल क्या है?',
    punchline: 'ब-ना-ना-ना।'
  },
  {
    setup: 'गणित की किताब उदास क्यों थी?',
    punchline: 'उसके पास बहुत सारी समस्याएँ थीं।              '
  },
  {
    setup: 'हैमबर्गर ने अपने बच्चे को क्या नाम दिया? ',
    punchline: 'पैटी। '
  },
  {
    setup: 'एक घर क्या पहनता है?',
    punchline: 'ए-ड्रेस।'
  },
  {
    setup: 'टूटी हुई पेंसिल से क्यों नहीं लिखना चाहिए?',
    punchline: 'क्योंकि यह पॉइंटलेस है। '
  },
  {
    setup: 'सबसे देशभक्ति भरा खेल कौन सा है?',
    punchline: 'फ़्लैग फुटबॉल।'
  },
  {
    setup: 'गुब्बारे के बारे में एक चुटकुला सुनाऊँ?',
    punchline: 'ओह रुको, मेरे दिमाग से ही उड़ गया!'
  },
  {
    setup: 'आप एक ऐसे आदमी को क्या कहते हैं जो खड़ा नहीं हो सकता है?',
    punchline: 'नील (Neil)'
  },
  {
    setup: 'छोटी माँ को क्या कहते हैं?',
    punchline: 'मिनिमम। '
  },
  {
    setup: 'केला डॉक्टर के पास क्यों गया?',
    punchline: 'ही वॉज़ नॉट पीलिंग वेल।             '
  },
  {
    setup: 'पक्षी अस्पताल में क्यों गया?',
    punchline: 'अपना ट्वीटमेंट (tweetment) करवाने के लिए।'
  },
  {
    setup: 'सांता संगीत सीखने के लिए कॉलेज क्यों गया?',
    punchline: 'ताकि वह अपने रैपिंग (wrapping) कौशल में सुधार कर सके।            '
  },
  {
    setup: 'सागर ने किनारे से क्या कहा?',
    punchline: 'कुछ नहीं - बस वेव किया।'
  },
  {
    setup: 'प्रिंटर से संगीत क्यों निकल रहा था?',
    punchline: 'कागज फिर से जाम कर रहा था।'
  },
  {
    setup: 'आप लाल पर कब निकल जाते हैं और हरे रंग पर कब रुक जाते हैं?',
    punchline: 'जब आप तरबूज खा रहे हों। '
  },
  {
    setup: 'बिना पैरों वाली गाय को आप क्या कहेंगे?',
    punchline: 'ग्राउंड बीफ़। '
  },
  {
    setup: 'बादल किस तरह के शॉर्ट्स पहनते हैं?',
    punchline: 'थंडरवियर।'
  },
  {
    setup: 'आप एक ऑक्टोपस को कैसे हंसाएंगे?',
    punchline: 'टेन-टिकल्स से। '
  },
  {
    setup: 'केकड़ा कभी कोई चीज़ साझा क्यों नहीं करता?',
    punchline: 'क्योंकि वह शेलफिश है।'
  },
  {
    setup: 'महिला अपने खरीदे हुए वेल्क्रो से खुश नहीं थी?',
    punchline: 'क्यूंकी वो एक रिपऑफ था।         '
  },
  {
    setup: 'ऐसी फ़ैक्टरी को आप क्या कहेंगे जो औसत उत्पाद बेचती है?',
    punchline: 'सैटिस-फ़ैक्टरी। '
  },
  {
    setup: 'मोटे पेंगुइन पार्टियों में इतने लोकप्रिय क्यों हैं?',
    punchline: 'वे जानते हैं कि आइस को ब्रेक कैसे करना है।'
  },
  {
    setup: 'वर्णमाला में सबसे ठंडा अक्षर कौन सा है?',
    punchline: 'B - यह AC के बीच में रहता है।'
  },
  {
    setup: 'उस आदमी को आप क्या कहेंगे जिसके पैर का अंगूठा (toe) रबड़ का है?',
    punchline: 'रॉबर-टो'
  },
  {
    setup: 'आपको आलसी लोगों पर क्रोधित क्यों नहीं होना चाहिए? ',
    punchline: 'क्यूंकि उन्होंने कुछ नहीं किया।'
  }
]
