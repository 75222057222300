import { JokeItem } from '../common/scripts/carousel/types/items'
import CONTENT_ITEMS from '../common/scripts/jokes_widget/content'
import initJokesWidgetBlock from '../common/scripts/jokes_widget/jokes_default'
import JokeCarouselWidget from '../common/scripts/jokes_widget/jokes_moment'
import { handleTouchEvents } from '../utilities/touchEvent'

const getItems = async (language: string, dynamic: boolean) => {
  if (dynamic) {
    return await window.CarouselLoader.load<JokeItem>('jokes', language)
  }

  return CONTENT_ITEMS[language] || CONTENT_ITEMS.en
}

const initDefault = async (language: string) => {
  if (document.documentElement.getAttribute('data-mp-app-theme') === 'moment') {
    return
  }

  const blocks: HTMLElement[] = Array.from(
    document.querySelectorAll(`.block--reveal-widget__jokes-widget`)
  )

  for (const block of blocks) {
    const isDynamic = block.classList.contains('jokes_dynamic_widget_block')
    const items = await getItems(language, isDynamic)

    initJokesWidgetBlock(block, items)
  }
}

const initMoment = async (language: string) => {
  if (document.documentElement.getAttribute('data-mp-app-theme') !== 'moment') {
    return
  }

  const blocks: HTMLElement[] = Array.from(
    document.querySelectorAll(`.block--reveal-widget__jokes-widget`)
  )

  for (const block of blocks) {
    const isDynamic = block.classList.contains('jokes_dynamic_widget_block')
    const items = await getItems(language, isDynamic)

    new JokeCarouselWidget(block, items, 'jokes_dynamic')
  }
}

const init = () => {
  const language = document.documentElement.lang
  // noinspection JSIgnoredPromiseFromCall
  initMoment(language)
  // noinspection JSIgnoredPromiseFromCall
  initDefault(language)
  handleTouchEvents('[data-mp-block-type="jokes_widget_block"] .swiper')
}

// Dynamic content loading requires async.  Need to make sure experiments have finished loading first.
if (window.experimentsLoaded) {
  init()
} else {
  document.addEventListener('experiment:initialized', () => {
    init()
  })
}
