import GA4Handler from '../GA4Handler'
import {
  DataLayerNonLinkClickElementAction,
  DataLayerRevealWidgetClickRefresh,
  DataLayerRevealWidgetClickShow,
  getBlockInteractionType
} from '../interfaces'
import { findClosestAncestorNode } from '../../utilities/DOM_manipulation'

const ga4RevealWidgetClickHandler = (
  block,
  contentType,
  action,
  teaser,
  detail,
  displayCount = null
) => {
  const section = findClosestAncestorNode(block, '[data-mp-block-type]')
  const blockIndex = section?.getAttribute('data-mp-block-index')
  const blockType = section?.getAttribute('data-mp-block-type')
  const experimentID = section.getAttribute('data-gtm-experiment-id')
  const experimentType = section.getAttribute('data-gtm-experiment-type')

  detail = detail.replace(/[^a-zA-Z0-9 ',.]/gs, ' ')

  let dataLayerObject

  switch (action) {
    case 'show':
    case 'answer':
      dataLayerObject = {
        event: DataLayerNonLinkClickElementAction.impression_click,
        element: blockType,
        block_layout: blockType,
        block_type: blockType,
        block_position: blockIndex,
        content_type: contentType,
        creator: 'ga4RevealWidgetClickHandler',
        domain: null,
        element_action: action,
        element_detail: detail,
        element_display_count: displayCount,
        interaction_type: getBlockInteractionType(blockType),
        path: null,
        publisher: null
      } as DataLayerRevealWidgetClickShow
      break
    case 'refresh':
      dataLayerObject = {
        block_layout: blockType,
        block_position: blockIndex,
        block_type: blockType,
        content_type: contentType,
        creator: 'ga4RevealWidgetClickHandler',
        domain: null,
        element: blockType,
        element_action: action,
        element_detail: detail || null,
        event: DataLayerNonLinkClickElementAction.impression_click,
        interaction_type: getBlockInteractionType(blockType),
        path: null,
        publisher: null
      } as DataLayerRevealWidgetClickRefresh
      break
  }

  // Add custom attributes for experiments.
  if (experimentID) {
    dataLayerObject.experiment_group = experimentType
    dataLayerObject.experiment_id = experimentID
    dataLayerObject.experiment_type = experimentType
  }

  GA4Handler.pushObject('ga4RevealWidgetClickHandler', dataLayerObject)
}

export default ga4RevealWidgetClickHandler
