export default [
  { setup: 'Qual è la lingua meno parlata al mondo?', punchline: 'Il linguaggio dei segni' },
  {
    setup: 'Cosa mangia uno zombie vegano?',
    punchline: '"GRRRANOLA!"'
  },
  {
    setup: 'Perché l’uomo invisibile ha rifiutato quell’offerta di lavoro?',
    punchline: 'Non riusciva a vedercisi.'
  },
  {
    setup: 'Perché gli elefanti sono rugosi?',
    punchline: 'Perché non si possono stirare.'
  },
  {
    setup: 'Cosa si dicono due cappelli in coda alla posta?',
    punchline: '"Non saltare in testa alla fila!"'
  },
  {
    setup: 'Come si chiama un cane magico?',
    punchline: 'Labracadabrador'
  },
  {
    setup: 'Ho inventato una nuova parola!',
    punchline: 'Plagio!'
  },
  {
    setup: 'Perché Helvetica e Times New Roman non vanno d’accordo?',
    punchline: 'Perché hanno caratteri diversi.'
  },
  {
    setup: 'Hai sentito del nuovo ristorante chiamato Karma?',
    punchline: 'Non c’è un menù. Ottieni quello che ti meriti.'
  },
  {
    setup: 'Un uomo dice al suo medico: "Dottore mi aiuti, sono dipendente da Twitter!"',
    punchline: 'Il medico risponde: "Mi dispiace, non ti seguo..."'
  },
  {
    setup: 'Cosa disse lo squalo quando mangiò il pesce pagliaccio?',
    punchline: '"Ha un sapore un po’ buffo"'
  },
  {
    setup: 'Perché le patatine se ne sono già andate?',
    punchline: 'Perché si sentivano di contorno.'
  },
  {
    setup: 'Cosa ottieni da una mucca a dieta?',
    punchline: 'Latte scremato.'
  },
  {
    setup: 'Perché l’M&M sta andando a scuola?',
    punchline: 'Perché vuole essere più Smartie.'
  },
  {
    setup: 'Perché il libro di matematica è in terapia da uno psicologo?',
    punchline: 'Per risolvere i suoi problemi.'
  },
  {
    setup: 'Come si chiama la femmina del Doberman? ',
    punchline: 'Doberwoman'
  },
  {
    setup: 'Perché quello spaventapasseri è così bravo in quello che fa?',
    punchline: 'Perché è nel suo campo!'
  },
  {
    setup: 'Perché lo scottex è caduto per terra dopo la mia barzelletta?',
    punchline: 'Perché si rotolava dalle risate!'
  },
  {
    setup: 'Ricorda, il lavoro di squadra è importante.',
    punchline: 'Dare la colpa a qualcun altro può essere sempre utile.'
  },
  {
    setup: 'Sono stato licenziato dalla fabbrica di calendari.',
    punchline: 'Eppure mi ero solo preso un giorno di ferie.'
  },
  {
    setup: 'Vuoi sentire una barzelletta sull’edilizia?',
    punchline: 'Te la racconto dopo - ci sto ancora lavorando.'
  },
  {
    setup: 'Cosa puoi afferrare senza mai toccarlo?',
    punchline: 'Il senso.'
  },
  {
    setup: 'Cosa può rispondere alla tua domanda in qualsiasi lingua?',
    punchline: 'L’eco.'
  },
  {
    setup: 'Cos’è che rimane sempre nell’angolo ma viaggia per il mondo?',
    punchline: 'Il francobollo.'
  },
  {
    setup: 'Hai sentito dell’astronauta claustrofobico?',
    punchline: 'Aveva solo bisogno di un po’ di spazio.'
  },
  {
    setup: 'Perché il semaforo è diventato rosso? ',
    punchline: 'Perché si vergognava di cambiarsi in mezzo alla strada!'
  },
  {
    setup: 'Cosa dice lo 0 all’8?',
    punchline: '"Bella cintura!"'
  },
  {
    setup: 'Perché le acciughe vivono in acqua salata? ',
    punchline: 'Perché hanno la pressione bassa.'
  },
  {
    setup: 'Dove tengono i loro risparmi gli orsi polari? ',
    punchline: 'In un banco di neve! '
  },
  {
    setup: 'Perché il cavallo è salito sul palco? ',
    punchline: 'Voleva cavalcare il momento!'
  },
  {
    setup: 'Perché il biscotto è andato dall’infermiera? ',
    punchline: 'Perché si sentiva scadente. '
  },
  {
    setup: 'Con quale mano è meglio scrivere? ',
    punchline: 'Nessuna delle due, è meglio scrivere con una matita. '
  },
  {
    setup: 'Cos’ha detto il lavandino al WC in palestra? ',
    punchline: 'Wow, sei davvero tirato! '
  },
  {
    setup: 'Cos’ha detto il semaforo alla macchina? ',
    punchline: 'Non mi guardare, mi fai arrossire. '
  },
  {
    setup: 'Perché una mano non può essere lunga 12 pollici?',
    punchline: 'Perché allora sarebbe un piede!'
  },
  {
    setup: 'Perché lo studente ha messo le scarpe da trekking per l’esame?',
    punchline: 'Perché il suo insegnante gli aveva detto che sarebbe stato una passeggiata!'
  },
  {
    setup: 'Perché i fantasmi vogliono sempre fare contratti?',
    punchline: 'Perché sono trasparenti!'
  },
  {
    setup: 'Come si chiamerà Terminator in pensione? ',
    punchline: 'Ex-Terminator.'
  },
  {
    setup: 'Quale oceano va d’accordo con tutti? ',
    punchline: 'Il Pacifico.'
  },
  {
    setup: 'Perché il libro di matematica è triste? ',
    punchline: 'Perché ha troppi problemi. '
  },
  {
    setup: 'Perché il musicista ha vinto il concorso?',
    punchline: 'Perché era nelle sue corde.'
  },
  {
    setup: 'Perché la penna ha vinto contro la matita?',
    punchline: 'Perché l’ha spuntata.'
  },
  {
    setup: 'Vuoi sentire una barzelletta su un palloncino? ',
    punchline: 'Oh aspetta, mi è scappata!'
  },
  {
    setup: 'Come si chiama un gruppo di orche che suona degli strumenti?',
    punchline: 'Un’orche-stra'
  },
  {
    setup: 'Perché i pinguini sono così popolari alle feste?',
    punchline: 'Perché sanno come rompere il ghiaccio.'
  },
  {
    setup: 'Perché il marinaio è andato dallo psicologo?',
    punchline: 'Perché la sua vita stava colando a picco.'
  },
  {
    setup: 'Come si chiama un uomo in mezzo al mare?',
    punchline: 'Bob'
  },
  {
    setup: 'Perché non ci si dovrebbe arrabbiare con le persone pigre?',
    punchline: 'Perché non hanno mai fatto niente.'
  },
  {
    setup: 'Perché il giovane astronauta ha sorpreso tutti i professori all’esame?',
    punchline: 'Perché sa come spaziare da un argomento all’altro.'
  }
]
