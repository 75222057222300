import Swiper from 'swiper'

import { shuffle } from '../utilities'
import i18n from '../../../utilities/translation'

// @todo This should be injected as a parameter. --hrivera
const language = document.documentElement.lang

interface ICarouselWidgetParams<T> {
  initialList: T[]
  identifier: string
  title: string
}

export interface ICarouselWidget<T> {
  getCarouselItemTemplate(item: T): string
}

abstract class CarouselWidget<T> {
  displayCount = 0
  initialList: T[]
  itemList: T[]
  container: HTMLElement
  section: HTMLElement
  identifier: string
  title: string

  constructor({ identifier, title, initialList }: ICarouselWidgetParams<T>) {
    this.identifier = identifier
    this.initialList = initialList
    this.title = title
  }

  abstract getCarouselItemTemplate(item: T): string
  abstract initContent(): void
  abstract setSectionData(data: Swiper, widgetContainer: HTMLElement): void

  getCarouselHeaderTemplate(title: string) {
    return `
      <div class='carousel__header'>
        <div>${i18n(`${title}`, language)}</div>
         
        <div class='block-item__actions'>
          <button id="personalize"
            data-mp-three-dots-button
            class="button button__icon button__icon--personalize svg--dark"
            type="button"
            data-gtm-id="three_dots" aria-label="{{t 'Three Dots' @root.language}}"
            data-modal-custom-trigger-target-personalization='modal--personalize'
            data-gtm-event-type="click-element"
            data-gtm-event-category="Three Dots">
            <div
              class="icon-three-dots"
              data-gtm-id="three_dots"
              data-gtm-event-type="click-element"
              data-gtm-event-category="Three Dots"
              data-gtm-event-label="{{ link }}"></div>
          </button>
        </div>
      </div>
    `
  }

  getCarouselTemplate(items: T[]) {
    const header = this.getCarouselHeaderTemplate(this.title)

    return `
      <div class='carousel__container' data-mp-block-type='carousel_widget'>
        ${header}
        <div class='swiper'>
          <div class='swiper-wrapper carousel__list'>
            ${items.map(item => this.getCarouselItemTemplate(item)).join('')}
          </div>
        </div>
        <div class='timer__container'></div>
      </div>
    `
  }

  listGet(list: T[], count: number) {
    return list.splice(0, count)
  }

  listRefresh() {
    this.itemList = shuffle(this.initialList)
  }

  async initBlock(block: HTMLElement) {
    this.section = block
    this.container = this.section.querySelector('.block-carousel')

    this.listRefresh()
    this.initContent()
  }

  onSlideChange(swiper: Swiper): void {
    this.setSectionData(swiper, this.section)

    if (this.itemList.length) {
      this.handleCarouselContent(swiper, this.itemList)
    } else {
      this.listRefresh()
      this.handleCarouselContent(swiper, this.itemList)
    }
  }

  handleCarouselContent(data: Swiper, items: T[]) {
    if (data.isEnd) {
      const nextItems = this.listGet(items, 1)
      const slideList = nextItems.map(item => this.getCarouselItemTemplate(item))
      data.appendSlide(slideList)
    }
  }
}

export default CarouselWidget
