export default [
  { setup: 'What do you call someone with no body and no nose?', punchline: 'Nobody knows.' },
  {
    setup: 'What is the least spoken language in the world?',
    punchline: 'Sign language'
  },
  {
    setup: 'What does a zombie vegetarian eat?',
    punchline: 'GRRRRRAAAAAIIIIINNNNS!'
  },
  {
    setup: 'Why did the invisible man turn down the job offer?',
    punchline: "He couldn't see himself doing it."
  },
  {
    setup: 'What did the pirate say on his 80th birthday?',
    punchline: 'AYE MATEY'
  },
  {
    setup: 'Why are elephants wrinkly?',
    punchline: "Because you can't iron them"
  },
  {
    setup: 'What did the policeman say to his belly button?',
    punchline: "You're under a vest!"
  },
  {
    setup: 'Why did the man get hit by a bike every day?',
    punchline: 'He was stuck in a vicious cycle.'
  },
  {
    setup: 'Why do people say “break a leg” when you go on stage?',
    punchline: 'Because every play needs a cast.'
  },
  { setup: 'What do sea monsters eat?', punchline: 'Fish and ships.' },
  {
    setup: 'How did the hipster burn his tongue?',
    punchline: 'He drank his coffee before it was cool.'
  },
  {
    setup: 'What kind of tea is hard to swallow?',
    punchline: 'Reality'
  },
  {
    setup: 'How did the barber win the race?',
    punchline: 'He knew a shortcut.'
  },
  {
    setup: 'Why are frogs so happy?',
    punchline: 'They eat whatever bugs them'
  },
  {
    setup: 'What did one hat say to the other?',
    punchline: "You stay here. I'll go on ahead."
  },
  {
    setup: 'Rest in peace boiling water...',
    punchline: "You'll be mist!"
  },
  {
    setup: 'How do you throw a space party?',
    punchline: 'You planet!'
  },
  {
    setup: 'Why did the gym close down?',
    punchline: "It just didn't work out"
  },
  {
    setup: 'Have you ever tried eating a clock?',
    punchline: "It's really time consuming. Especially if you go for seconds."
  },
  {
    setup: "What is Forrest Gump's password?",
    punchline: '1Forrest1'
  },
  {
    setup: 'What do you call a magic dog?',
    punchline: 'A labracadabrador'
  },
  {
    setup: 'I invented a new word!',
    punchline: 'Plagiarism!'
  },
  {
    setup: 'Did you hear about the mathematician who’s afraid of negative numbers?',
    punchline: 'He’ll stop at nothing to avoid them.'
  },
  {
    setup: 'Why do we tell actors to “break a leg?”',
    punchline: 'Because every play has a cast.'
  },
  {
    setup: 'Helvetica and Times New Roman walk into a bar.',
    punchline: '“Get out of here!” shouts the bartender. “We don’t serve your type.”'
  },
  {
    setup: 'Did you hear about the actor who fell through the floorboards?',
    punchline: 'He was just going through a stage'
  },
  {
    setup: 'Hear about the new restaurant called Karma?',
    punchline: 'There’s no menu. You get what you deserve.'
  },
  {
    setup: 'A woman in labor suddenly shouted, “Shouldn’t! Wouldn’t! Couldn’t! Didn’t! Can’t!”',
    punchline: '“Don’t worry,” said the doc. “Those are just contractions.”'
  },
  {
    setup: 'Why don’t scientists trust atoms?',
    punchline: 'Because they make up everything.'
  },
  {
    setup: 'Where are average things manufactured?',
    punchline: 'The satisfactory.'
  },
  {
    setup: 'What sits at the bottom of the sea and twitches?',
    punchline: 'A nervous wreck.'
  },
  {
    setup: 'What does a nosy pepper do?',
    punchline: 'Gets jalapeño business!'
  },
  {
    setup: 'Why can’t you explain puns to kleptomaniacs?',
    punchline: 'They always take things, literally.'
  },
  {
    setup: 'A man tells his doctor, “Doc, help me. I’m addicted to Twitter!”',
    punchline: 'The doctor replies, “Sorry, I don’t follow you …”'
  },
  {
    setup: 'What kind of exercise do lazy people do?',
    punchline: 'Diddly-squats.'
  },
  {
    setup: 'What do you call a parade of rabbits hopping backwards?',
    punchline: 'A receding hare-line.'
  },
  {
    setup: 'What does Charles Dickens keep in his spice rack?',
    punchline: 'The best of thymes, the worst of thymes.'
  },
  {
    setup: 'What did the bald man exclaim when he received a comb for a present?',
    punchline: 'Thanks— I’ll never part with it!'
  },
  {
    setup: 'What did the left eye say to the right eye?',
    punchline: 'Between you and me, something smells.'
  },
  {
    setup: 'What do you call a fake noodle?',
    punchline: 'An impasta.'
  },
  {
    setup: 'How do you make a tissue dance?',
    punchline: 'Put a little boogie in it.'
  },
  {
    setup: 'What did one hat say to the other?',
    punchline: 'You wait here. I’ll go on a head.'
  },
  {
    setup: 'What did the shark say when he ate the clownfish?',
    punchline: 'This tastes a little funny.'
  },
  {
    setup: 'Why did the yogurt go to the art exhibition?',
    punchline: 'Because it was cultured.'
  },
  {
    setup: 'Why did the Oreo go to the dentist?',
    punchline: 'Because he lost his filling.'
  },
  {
    setup: 'What do you get from a pampered cow?',
    punchline: 'Spoiled milk.'
  },
  {
    setup: 'Why did the M&M go to school?',
    punchline: 'It wanted to be a Smartie.'
  },
  {
    setup: 'Rest in peace to boiling water.',
    punchline: 'You will be mist.'
  },
  {
    setup: 'What do you call a rooster staring at a pile of lettuce?',
    punchline: 'A chicken sees a salad.'
  },
  {
    setup: 'Why did the nurse need a red pen at work?',
    punchline: 'In case she needed to draw blood.'
  },
  {
    setup: 'The numbers 19 and 20 got into a fight.',
    punchline: '21'
  },
  {
    setup: 'Why did it get so hot in the baseball stadium after the game?',
    punchline: 'All of the fans left.'
  },
  {
    setup: 'Why did the math textbook visit the guidance counselor?',
    punchline: 'It needed help figuring out its problems.'
  },
  {
    setup: 'Why can’t male ants sink?',
    punchline: 'Because they’re buoy-ant.'
  },
  {
    setup: 'What do you call bears with no ears?',
    punchline: 'B'
  },
  {
    setup: 'Two gold fish are in a tank.',
    punchline: 'One looks at the other and says, “You know how to drive this thing?!”'
  },
  {
    setup: 'As a scarecrow, people say I’m outstanding in my field.',
    punchline: 'But hay, it’s in my jeans.'
  },
  {
    setup: 'I waited and stayed up all night and tried to figure out where the sun was.',
    punchline: 'Then it dawned on me.'
  },
  {
    setup: 'What do Alexander the Great and Winnie the Pooh have in common?',
    punchline: 'Same middle name.'
  },
  {
    setup: 'Sometimes I tuck my knees into my chest and lean forward.',
    punchline: 'That’s just how I roll.'
  },
  {
    setup: 'Remember, teamwork is important.',
    punchline: 'It helps put the blame on someone else.'
  },
  {
    setup: 'I just heard the company’s going to fire the employees with the worst posture.',
    punchline: 'I don’t know who did it, but I have a hunch.'
  },
  {
    setup: 'I got fired from the calendar factory.',
    punchline: 'Don’t know why — all I did was take a day off.'
  },
  {
    setup: 'Want to hear a joke about a skunk?',
    punchline: 'Never mind, it really stinks.'
  },
  {
    setup: 'Do you want to hear a construction joke?',
    punchline: 'I’ll tell you later — still working on it.'
  },
  {
    setup: 'What you can hold without ever touching it?',
    punchline: 'A conversation.'
  },
  {
    setup: 'How many sides are there to a circle?',
    punchline: 'Two. The inside and the outside.'
  },
  {
    setup: 'What can answer your question in any language?',
    punchline: 'An echo.'
  },
  {
    setup: 'What has a tail and a head, but no body?',
    punchline: 'A coin.'
  },
  {
    setup: 'What stays in the corner all the time but travels around the world?',
    punchline: 'A stamp.'
  },
  {
    setup: 'What has three ways out and just one way in?',
    punchline: 'A t-shirt.'
  },
  {
    setup: 'Why are obtuse angles so depressed?',
    punchline: 'Because they’re never right.'
  },
  {
    setup: 'Why shouldn’t you let advanced math intimidate you? ',
    punchline: 'It’s really as easy as pi! '
  },
  {
    setup: 'What lights up a soccer stadium?',
    punchline: 'A soccer match.'
  },
  {
    setup: 'What did the fisherman say to the magician? ',
    punchline: 'Pick a cod, any cod! '
  },
  {
    setup: 'Are monsters good at math? ',
    punchline: 'Not unless you Count Dracula. '
  },
  {
    setup: 'Why are fish so smart? ',
    punchline: 'Because they live in schools! '
  },
  {
    setup: 'What breed of dog can jump higher than buildings? ',
    punchline: 'Any dog, because buildings can’t jump. '
  },
  {
    setup: 'What did the big flower say to the little flower?',
    punchline: 'Hi, Bud!'
  },
  {
    setup: 'Why do bees have sticky hair?',
    punchline: 'Because they use honeycombs.'
  },
  {
    setup: 'Why did the police arrest the turkey?',
    punchline: 'They suspected fowl play.'
  },
  {
    setup: 'What’s the best thing about Switzerland? ',
    punchline: 'I don’t know, but the flag is a big plus.'
  },
  {
    setup: 'Did you hear about the actor who fell through the floorboards?',
    punchline: 'He was just going through a stage.'
  },
  {
    setup: 'Did you hear about the claustrophobic astronaut?',
    punchline: 'He just needed a little space.'
  },
  {
    setup: 'Why don’t scientists trust atoms? ',
    punchline: 'Because they make up everything. '
  },
  {
    setup: 'Why did the chicken go to the séance? ',
    punchline: 'To get to the other side. '
  },
  {
    setup: 'How do you drown a hipster? ',
    punchline: 'Throw him in the mainstream. '
  },
  {
    setup: 'Why did the stoplight turn red? ',
    punchline: 'Because it was embarrassed to be changing in the middle of the street!'
  },
  {
    setup: 'Why does it take pirates so long to learn the alphabet? ',
    punchline: 'Because they spend years at C. '
  },
  {
    setup: 'What did 0 say to 8?',
    punchline: '"Nice belt."'
  },
  {
    setup: 'Why do fish live in salt water? ',
    punchline: 'Because if they lived in pepper water, they would sneeze.'
  },
  {
    setup: 'Why did the kid throw his clock out of the window? ',
    punchline: 'Because he wanted to see time fly. '
  },
  {
    setup: 'Where do polar bears keep their money? ',
    punchline: 'In a snow bank! '
  },
  {
    setup: 'Why did the pony get sent to his room? ',
    punchline: 'He wouldn’t stop horsing around!'
  },
  {
    setup: 'What do you call a bear with no ears? ',
    punchline: 'A "B."'
  },
  {
    setup: 'What do you call a cheese that’s not yours? ',
    punchline: 'Nacho cheese! '
  },
  {
    setup: 'Why wouldn’t the shrimp share his treasure? ',
    punchline: 'Because he was a little shellfish. '
  },
  {
    setup: 'Why is Cinderella bad at soccer? ',
    punchline: 'Because she’s always running away from the ball! '
  },
  {
    setup: 'Why did the picture go to prison? ',
    punchline: 'Because it was framed. '
  },
  {
    setup: 'Where do cows go on Friday nights? ',
    punchline: 'To the moo-vies. '
  },
  {
    setup: 'What did one eye say to the other eye? ',
    punchline: 'Between us, something smells. '
  },
  {
    setup: 'Why do bicycles fall over?  ',
    punchline: 'Because they’re two-tired! '
  },
  {
    setup: 'Why couldn’t the pony sing "Happy Birthday?"',
    punchline: 'Because she was a little horse.'
  },
  {
    setup: 'If a seagull flies over the sea, what flies over the bay?',
    punchline: 'A bagel.'
  },
  {
    setup: 'What did the buffalo say when his little boy left for school?',
    punchline: 'Bison!'
  },
  {
    setup: 'Why did the cookie go to the nurse? ',
    punchline: 'Because he felt crummy.'
  },
  {
    setup: 'Why do vampires seem sick all the time?',
    punchline: 'Because they’re always coffin.'
  },
  {
    setup: 'What kind of room doesn’t have doors?',
    punchline: 'A mushroom.'
  },
  {
    setup: 'Which hand is it better to write with?',
    punchline: 'Neither, it’s better to write with a pencil.'
  },
  {
    setup: 'What animal can you always find at a baseball game?',
    punchline: 'A bat!'
  },
  {
    setup: 'What does the ocean do when it sees its friends? ',
    punchline: 'It waves!'
  },
  {
    setup: 'What did one snowman say to the other snowman? ',
    punchline: 'It smells like carrots over here! '
  },
  {
    setup: 'Why did the golfer wear two pairs of pants?',
    punchline: 'Just in case he got a hole in one!'
  },
  {
    setup: 'How do you make an octopus laugh?',
    punchline: 'With ten-tickles.'
  },
  {
    setup: 'What has four wheels and flies?',
    punchline: 'A garbage truck.'
  },
  {
    setup: 'Why do bicycles fall over?',
    punchline: 'Wow, you look really flushed!'
  },
  {
    setup: 'What kind of keys open a banana?',
    punchline: 'A mon-key.'
  },
  {
    setup: 'What’s the difference between a guitar and a fish?',
    punchline: 'You can tune a guitar, but you can’t tuna fish.'
  },
  {
    setup: 'What did the traffic light say to the car?',
    punchline: 'Look away, I’m about to change.'
  },
  {
    setup: 'What gets wetter the more that it dries?',
    punchline: 'A towel. '
  },
  {
    setup: 'Why was the broom late?',
    punchline: 'It over-swept.'
  },
  {
    setup: 'Why did the teddy bear not ask for dessert?',
    punchline: 'Because he was already so stuffed!'
  },
  {
    setup: 'What do you call a fake noodle?',
    punchline: 'An impasta!'
  },
  {
    setup: 'Why can’t a hand be 12 inches long?',
    punchline: 'Because then it would be a foot!'
  },
  {
    setup: 'Why did the student eat his homework?',
    punchline: 'Because his teacher told him it would be a piece of cake!'
  },
  {
    setup: 'How do you get an astronaut’s baby to stop crying?',
    punchline: 'You rocket!'
  },
  {
    setup: 'Why are ghosts bad liars?',
    punchline: 'Because you can see right through them!'
  },
  {
    setup: 'What do you call a dinosaur with bad vision?',
    punchline: 'A Do-you-think-he-sarus!'
  },
  {
    setup: 'Which bear is the most condescending?',
    punchline: 'A pan-duh!'
  },
  {
    setup: 'How do you get a country girl’s attention?',
    punchline: 'A tractor.'
  },
  {
    setup: 'What do you call a pudgy psychic?',
    punchline: 'A four-chin teller.'
  },
  {
    setup: 'What would the Terminator be called in his retirement? ',
    punchline: 'The Exterminator.'
  },
  {
    setup: 'Why do some couples go to the gym?',
    punchline: 'Because they want their relationship to work out.'
  },
  {
    setup: 'What’s the most detail-oriented ocean?',
    punchline: 'The Pacific.'
  },
  {
    setup: 'What do you call a bear without teeth?',
    punchline: 'A gummy bear'
  },
  {
    setup: 'What do dentists call their x-rays?',
    punchline: 'Tooth-pics'
  },
  {
    setup: 'What do you call a cow with a twitch?',
    punchline: 'Beef jerky'
  },
  {
    setup: 'What is Beethoven’s favorite fruit?',
    punchline: 'BA-NA-NA-NAAAA'
  },
  {
    setup: 'Why was the math book sad?',
    punchline: 'It had too many problems.'
  },
  {
    setup: 'What did the hamburger name its baby?',
    punchline: 'Patty'
  },
  {
    setup: 'Why did the man fall down the well?',
    punchline: 'Because he couldn’t see that well.'
  },
  {
    setup: 'When does a joke become a “dad joke?”',
    punchline: 'When it becomes apparent.'
  },
  {
    setup: 'What did the flowers do when the bride walked down the aisle?',
    punchline: 'They rose.'
  },
  {
    setup: 'What kind of shoes does a lazy person wear?',
    punchline: 'Loafers.'
  },
  {
    setup: 'Why do melons have weddings?',
    punchline: 'Because they cantaloupe.'
  },
  {
    setup: 'What did the drummer call his twin daughters?',
    punchline: 'Anna One, Anna Two!'
  },
  {
    setup: 'What does a nosey pepper do? ',
    punchline: 'It gets jalapeño business.'
  },
  {
    setup: 'What does a house wear? ',
    punchline: 'Address.'
  },
  {
    setup: 'Why did the scarecrow win an award? ',
    punchline: 'He was out standing in his field.'
  },
  {
    setup: 'Which U.S. state is known for its especially small soft drinks? ',
    punchline: 'Minnesota.'
  },
  {
    setup: 'What do sprinters eat before a race? ',
    punchline: 'Nothing—they fast.'
  },
  {
    setup: 'What do you call a fibbing cat?',
    punchline: 'A lion.'
  },
  {
    setup: 'Why shouldn’t you write with a broken pencil? ',
    punchline: '"Because it’s pointless.'
  },
  {
    setup: 'What’s the most patriotic sport?',
    punchline: 'Flag football.'
  },
  {
    setup: 'Want to hear a joke about a balloon?Want to hear a joke about a balloon?',
    punchline: 'Oh wait, it just got away from me!'
  },
  {
    setup: 'What do you call a man who can’t stand? ',
    punchline: 'Neil'
  },
  {
    setup: 'What do you call a small mother?',
    punchline: 'A minimum'
  },
  {
    setup: 'What lies at the bottom of the ocean and twitches?',
    punchline: 'A nervous wreck'
  },
  {
    setup: 'What’s the worst thing about sea sickness?',
    punchline: 'It comes in waves.'
  },
  {
    setup: 'Why did the banana go to the doctor?',
    punchline: 'It wasn’t peeling well.'
  },
  {
    setup: 'What do you call an upset brownie?',
    punchline: 'Frownie'
  },
  {
    setup: 'Why did the bird go to the hospital?',
    punchline: 'To get tweetment'
  },
  {
    setup: 'Why did Santa go to college for music?',
    punchline: 'So he could improve his wrapping skills.'
  },
  {
    setup: 'Why was there music coming out of the printer?',
    punchline: 'The paper was jammin’ again'
  },
  {
    setup: 'When do you go at red and stop at green?',
    punchline: 'When you’re eating a watermelon.'
  },
  {
    setup: 'What does a clock do when its hungry?',
    punchline: 'It goes back four seconds.'
  },
  {
    setup: 'What do you call a cow with no legs?',
    punchline: 'Ground beef.'
  },
  {
    setup: 'What do you call an everyday potato?',
    punchline: 'A commentator'
  },
  {
    setup: 'What kind of shorts do clouds wear?',
    punchline: 'Thunderwear'
  },
  {
    setup: 'What do you call an owl that does magic tricks?',
    punchline: 'Hoodini'
  },
  {
    setup: 'What do you call a group of killer whales playing instruments?',
    punchline: 'An orca-stra'
  },
  {
    setup: 'How do you find Will Smith in the snow?',
    punchline: 'You look for the fresh prints.'
  },
  {
    setup: 'What’s the difference between a hippo and a zippo?',
    punchline: 'One is really heavy, the other is a little lighter.'
  },
  {
    setup: 'Where did the college-age vampire like to shop?',
    punchline: 'Forever 21'
  },
  {
    setup: 'What did the horse say after it tripped?',
    punchline: 'Help! I’ve fallen and I can’t giddyup!'
  },
  {
    setup: 'Why wasn’t the woman happy with the velcro she bought?',
    punchline: 'It was a total ripoff'
  },
  {
    setup: 'What do you get when you cross a vampire with a snowman?',
    punchline: 'Frostbite'
  },
  {
    setup: 'What does an angry pepper do?',
    punchline: 'It gets jalapeño face.'
  },
  {
    setup: 'Want to hear a joke about a piece of paper?',
    punchline: 'Nevermind, it’s tearable'
  },
  {
    setup: 'What do you call a lonely cheese?',
    punchline: 'Provolone.'
  },
  {
    setup: 'Why was 2019 afraid of 2020?',
    punchline: 'They had a fight and 2021'
  },
  {
    setup: 'Why are fat penguins so popular at parties?',
    punchline: 'They know how to break the ice.'
  },
  {
    setup: 'What did the skier say to the therapist?',
    punchline: 'My life is going downhill.'
  },
  {
    setup: 'How many birds does it take to change a lightbulb?',
    punchline: 'Ideally three, but Toucan'
  },
  {
    setup: 'What do you call a man with no arms or legs in the middle of the sea?',
    punchline: 'Bob'
  },
  {
    setup: 'What’s the coldest letter in the alphabet?',
    punchline: 'B — it’s between AC'
  },
  {
    setup: 'What do you call a guy who has a rubber toe?',
    punchline: 'Roberto'
  },
  {
    setup: 'Why shouldn’t you get mad at lazy people?',
    punchline: 'They didn’t do anything.'
  },
  {
    setup: 'Where do you buy purple things?',
    punchline: 'The La-vendor'
  },
  {
    setup: 'What did the giraffe say before it was attacked by the jungle cat?',
    punchline: 'Easy tiger!'
  },
  {
    setup: 'When should an astronaut retire?',
    punchline: 'When they start spacing out'
  },
  {
    setup: 'What sits up in a tree and goes "Aaaargh"?',
    punchline: 'An owl with a speech impediment.'
  },
  {
    setup:
      'Imagine if you walked into a bar and there was a long line of people waiting to take a swing at you.',
    punchline: 'That is the punch line.'
  },
  {
    setup: 'How does a man on the moon cut his hair?',
    punchline: 'Eclipse it.'
  },
  {
    setup: 'Not to brag, but I defeated our local chess champion in less than five moves.',
    punchline: 'Finally, my high school karate lessons paid off.'
  },
  {
    setup: 'Air was free at the gas station, now it costs $1.50. You know why?',
    punchline: 'Inflation!!'
  },
  {
    setup: 'Why is it a bad idea to iron your four-leaf clover?',
    punchline: "Cause you shouldn't press your luck."
  },
  {
    setup: "What rock group has four men that don't sing? ",
    punchline: 'Mount Rushmore.'
  },
  {
    setup: 'What do sprinters eat before a race? ',
    punchline: 'Nothing, they fast!'
  },
  {
    setup: 'What do you call it when Batman skips church? ',
    punchline: 'Christian Bale.'
  },
  {
    setup: 'Why does Snoop Dogg always carry an umbrella?',
    punchline: "Fo' Drizzle."
  },
  {
    setup: 'What do you call a fake noodle?',
    punchline: 'An impasta!'
  },
  {
    setup: 'How do you organize a space party?',
    punchline: 'You planet'
  },
  {
    setup: 'What does a baby computer call his father?',
    punchline: 'Data'
  },
  {
    setup: 'Wanna hear a joke about paper? ',
    punchline: "Never mind—it's tearable."
  },
  {
    setup: "Don't trust atoms.",
    punchline: 'They make up everything!'
  },
  {
    setup: 'Did you hear about the restaurant on the moon?',
    punchline: 'Great food, no atmosphere!'
  },
  {
    setup: 'How many apples grow on a tree?',
    punchline: 'All of them!'
  },
  {
    setup: 'Why did the invisible man turn down the job offer?',
    punchline: "He couldn't see himself doing it!"
  },
  {
    setup: 'Want to hear a joke about construction?',
    punchline: "I'm still working on it!"
  },
  {
    setup: 'What do you call a shoe made of a banana?',
    punchline: 'A slipper!'
  },
  {
    setup: 'How do pirates know that they are pirates?',
    punchline: 'They think, therefore they ARRRR!!!!!'
  }
]
