import Swiper from 'swiper'

import CarouselWidget, { ICarouselWidget } from '../carousel/carousel_builder'
import GA4Handler from '../ga4_support/GA4Handler'
import Timer from '../timer'
import ga4RevealWidgetClickHandler from '../ga4_support/blocks/reveal_widget'
import i18n from '../../../utilities/translation'
import initActionButtons from '../action_buttons/action_buttons'
import { Carousel } from '../carousel'
import { IJoke } from './types'
import { cropImage } from '../utilities/imageManipulation'
import { getAssetDomainByMPGlobals } from 'lib/deployment'
import { handleTouchEvents } from '../../../utilities/touchEvent'
import { lazyload } from '../utilities/DOM_events'
import { v4 as uuid } from 'uuid'

const language = document.documentElement.lang

class JokeCarouselWidget extends CarouselWidget<IJoke> implements ICarouselWidget<IJoke> {
  timer: Timer
  refreshCount = {}

  constructor(block: HTMLElement, initialList: IJoke[], identifier: string) {
    super({ initialList, identifier, title: 'Jokes' })
    // noinspection JSIgnoredPromiseFromCall
    this.initBlock(block)
  }

  initContent() {
    const carouselLayout: string = this.getCarouselTemplate(this.listGet(this.itemList, 3))
    const articleId = `${this.identifier}_widget_block-${uuid()}`

    GA4Handler.impressionHandleEvent(this.section)

    // Need to set a variable that fires show when the block comes into view.
    lazyload(
      this.section,
      () => {
        this.section.setAttribute('id', articleId)
        this.container.innerHTML = carouselLayout

        this.timer = new Timer({
          container: this.container.querySelector('.timer__container'),
          time: 5
        })

        this.timer.timerEl.addEventListener('timer-end', () => {
          this.onTimerEnd(this.section)
        })

        const carousel = new Carousel({
          slideChange: (swiper: Swiper) => this.onSlideChange(swiper),
          block: this.container,
          onItemClick: () => this.onItemClick()
        })
        carousel.init()
        initActionButtons(`#${articleId}`)
        this.setSectionData(carousel.swiper, this.section)
        handleTouchEvents(`#${articleId} .swiper`)
      },
      {
        threshold: 0.4 // This might need to be tweaked for carousel.
      }
    )
  }

  onTimerEnd(wrapper: HTMLElement) {
    const activeElement = wrapper.querySelector('.carousel__item--active')
    activeElement.classList.add('carousel__item--answer-visible-now')
    const title = activeElement.querySelector('.carousel__text').textContent
    const description = activeElement.querySelector('.carousel__answer').textContent
    wrapper.setAttribute('data-gtm-feature', 'show')
    wrapper.setAttribute('data-gtm-share-text', `${title} \n ${description}`)

    // Don't post an event until the second click.
    ga4RevealWidgetClickHandler(
      wrapper,
      'editorial',
      'show',
      title,
      description,
      ++this.displayCount
    )
  }

  getCarouselItemTemplate(item: IJoke) {
    return `
        <div
          data-opacity='0.7'
          data-title-text='${item.punchline}'
          class='carousel__item swiper-slide__joke carousel__item--large swiper-slide'>
          <div class='carousel__fake-background'></div>
          <div class='carousel__title'>
            <p class='carousel__text'>${item.setup}</p>
            <p class='carousel__answer'>${item.punchline}</p>
          </div>
        </div>
    `
  }

  setSectionData(data: Swiper, widgetContainer: HTMLElement) {
    const id = widgetContainer.getAttribute('id')
    const activeElement = data.slides[data.activeIndex]
    const title = activeElement.querySelector('.carousel__text').textContent
    const description = activeElement.querySelector('.carousel__answer').textContent

    widgetContainer.setAttribute('data-carousel-title', `${title} \n ${description}`)
    widgetContainer.setAttribute('data-carousel-description', i18n('Have a laugh!', language))
    const jokeLogo = cropImage(
      `https://${getAssetDomainByMPGlobals()}/image/upload/v1681805554/app/icons/joke.svg`,
      getAssetDomainByMPGlobals(),
      { size: 'clamped', protocol: 'https:' }
    )
    widgetContainer.setAttribute('data-carousel-img-src', jokeLogo)
    widgetContainer.setAttribute(
      `data-mp-share-${this.identifier}-text`,
      `${title} \n ${description}`
    )

    if (this.refreshCount[id]) {
      ga4RevealWidgetClickHandler(widgetContainer, 'editorial', 'refresh', '', title)
    } else {
      this.refreshCount[id] = 1
    }
  }

  onSlideChange(swiper: Swiper) {
    super.onSlideChange(swiper)
    const nextSlide = swiper.slides[swiper.activeIndex + 1]
    const prevSlide = swiper.slides[swiper.activeIndex - 1]
    const activeElement = swiper.slides[swiper.activeIndex]
    const title = activeElement.querySelector('.carousel__text').textContent
    const description = activeElement.querySelector('.carousel__answer').textContent

    this.section.setAttribute(`data-mp-share-${this.identifier}-text`, `${title} \n ${description}`)
    this.section.setAttribute('data-gtm-feature', 'refresh')

    if (nextSlide) {
      nextSlide.classList.remove('carousel__item--answer-visible-now')
    }

    if (prevSlide) {
      prevSlide.classList.remove('carousel__item--answer-visible-now')
    }

    if (this.timer) {
      this.timer.reset()
    }
  }

  onItemClick() {
    this.timer?.destroy()
  }
}

export default JokeCarouselWidget
