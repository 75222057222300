export default [
  {
    setup: 'Que se passe-t-il quand deux poissons commencent à s’énerver ?',
    punchline: 'Le thon monte.'
  },
  {
    setup: 'Que dit une noisette lorsqu’elle tombe à l’eau ? ',
    punchline: 'Je me noix.'
  },
  {
    setup: 'L’autre jour j’ai raconté des blagues en rangeant mes vêtements.',
    punchline: 'Ils étaient pliés en deux. '
  },
  {
    setup: 'Pourquoi l’homme invisible a-t-il refusé l’offre d’emploi ?',
    punchline: 'Il ne se voyait pas faire ce genre de travail.'
  },
  {
    setup: '"La maîtresse demande à sa classe; Quel est le futur de je bâille ?"',
    punchline: 'Et Toto répond : Je dors !'
  },
  {
    setup: 'Pourquoi les éléphants sont-ils ridés ?',
    punchline: 'Parce qu’on ne peut pas les repasser.'
  },
  {
    setup: 'C’est deux machines à coudre sur une table. Une machine tombe en panne.',
    punchline: ' L’autre dit : Ah bien coudonc.'
  },
  {
    setup: 'Un gars est parti prendre une marche.',
    punchline: 'Il est revenu avec l’escalier.'
  },
  {
    setup: 'Je viens de voir un hibou se briser une patte.',
    punchline: 'Maintenant hibouette.'
  },
  {
    setup: 'Je creuse. Tu creuses. Il creuse.',
    punchline: 'C’est pas le meilleur poème mais c’est très profond.'
  },
  {
    setup: '"La maîtresse demande à Toto; Où est mort Napoléon ?"',
    punchline: 'À la page 32 du livre d’histoire maîtresse !'
  },
  {
    setup: 'Quel genre de thé est difficile à avaler ?',
    punchline: 'Réalité.'
  },
  {
    setup: 'Un couteau dit à l’autre couteau',
    punchline: 'T’as pas l’air à filer.'
  },
  {
    setup: 'Le fruit que les poissons détestent le plus ?',
    punchline: 'La pêche.'
  },
  {
    setup: 'Quels sont les deux animaux les plus intelligents ?',
    punchline: 'Le Cerf et le Veau (Cerveau).'
  },
  {
    setup: 'Pourquoi le mer monte et descend ?',
    punchline: 'Parce que ça la fait marée.'
  },
  {
    setup: 'Comment appelle-t-on une star du cinéma aquatique ?',
    punchline: 'Une étoile de mer.'
  },
  {
    setup: 'Comment appelez-vous un chien magique ?',
    punchline: 'Un labracadabrador.'
  },
  {
    setup: 'J’ai inventé un nouveau mot !',
    punchline: 'Plagiat !'
  },
  {
    setup: 'Avez-vous entendu parler du mathématicien qui a peur des nombres négatifs?',
    punchline: 'Il ne reculera devant rien pour les éviter. '
  },
  {
    setup: 'Quel poisson n’a jamais vu l’eau ?',
    punchline: 'La poisson d’Avril.'
  },
  {
    setup:
      'Toto rentre de l’école…Alors, comment étaient les questions de l’interrogation d’aujourd’hui ? Demande sa mère.',
    punchline:
      'Aucun problème avec les questions maman ! Par contre, les réponses c’était compliqué.'
  },
  {
    setup: 'As-tu entendu parler du nouveau restaurant appelé Karma ?',
    punchline: 'Il n’y a pas de menu. On te sert ce que tu mérites.'
  },
  {
    setup: '""Une poule hors de son poulailler dit: Il fait un froid de canard !"',
    punchline:
      '"Un canard sort de l’autre côté de la ferme et dit: Ne m’en parle pas, j’ai la chair de poule !"'
  },
  {
    setup: 'Deux escargots viennent d’arriver sur une plage et voient une limace :',
    punchline: 'Allons-nous-en, c’est une plage nudiste !'
  },
  {
    setup: 'Comment sortir du casino avec une petite fortune ?',
    punchline: 'Le mot enveloppe.'
  },
  {
    setup: 'Pourquoi ne pouvez-vous pas expliquer les jeux de mots aux kleptomans ?',
    punchline: 'Ils prennent toujours les choses littéralement.'
  },
  {
    setup: 'Un homme dit à son médecin: Docteur, aidez-moi. Je suis accro à Twitter!',
    punchline: 'Le médecin répond: Désolé, je ne vous suis pas.'
  },
  {
    setup: 'Quelles sont les lettres qui se voient le moins ?',
    punchline: 'F A C (effacer).'
  },
  {
    setup:
      '"La maman de Toto s’étonne: Pourquoi manges-tu ta chocolatine en te regardant dans le miroir ?"',
    punchline: 'Pour avoir l’impression d’en manger deux !'
  },
  {
    setup: 'Qu’est-ce que l’oeil gauche a dit à l’œil droit ?',
    punchline: 'Je sens quelqu’il y a quelque chose entre toi et moi.'
  },
  {
    setup: 'Qu’est-ce qui pleure quand on lui tourne la tête ?',
    punchline: 'Un robinet. '
  },
  {
    setup:
      '"Toto à la grippe. Le docteur l’examine longuement et Toto finit par lui dire: Docteur, je vous promets d’être courageux. "',
    punchline: 'Dites-moi la vérité : quand dois-je retourner à l’école ?'
  },
  {
    setup: 'Qu’est-ce que le requin a dit quand il a mangé le poisson-clown ?',
    punchline: 'Ça goûte drôle…'
  },
  {
    setup: '"La maîtresse s’adresse à Toto: Peux-tu me citer un mammifère qui n’a pas de dents ?"',
    punchline: 'Euh… Mon grand-père ?'
  },
  {
    setup: 'Deux petits pois discutent :',
    punchline: 'Tu étais où hier ? On ne t’a pas vu en boîte !'
  },
  {
    setup: 'Deux bombes discutent :',
    punchline: 'Rendez-vous à 17 heures pétantes !'
  },
  {
    setup: '"Dans un magasin : Je peux essayer ce pantalon en vitrine ?"',
    punchline: 'Vous ne préférez pas l’essayer en cabine ?'
  },
  {
    setup: '"Une mandarine va rendre visite à une clémentine malade : Alors, que t’arrive-t-il ?"',
    punchline: 'Que des pépins !'
  },
  {
    setup:
      '"Deux cosmonautes discutent : Tu crois qu’on peut se cogner contre quelqu’un en apesanteur ?"',
    punchline: 'Oui, mais ce sont toujours des accidents sans gravité !'
  },
  {
    setup: 'La maîtresse demande à Toto de faire une phrase avec le maximum de mots.',
    punchline:
      'Toto répond : Quand je cherche mon chat, je l’appelle : minou, minou, minou, minou, minou, minou, minou, minou…'
  },
  {
    setup:
      '"Merlin rencontre Arthur pour la première fois. Arthur regarde le vieillard et lui dit : Enchanté !"',
    punchline: 'L’autre répond : Non, non, enchanteur !'
  },
  {
    setup: 'Pourquoi le manuel de mathématiques a-t-il rendu visite au conseiller d’orientation ? ',
    punchline: 'Il avait besoin d’aide pour comprendre ses problèmes.'
  },
  {
    setup: 'Qu’est-ce qu’un squelette dans un placard ?',
    punchline: 'C’est quelqu’un qui a gagné à cache-cache.'
  },
  {
    setup: 'Pourquoi faut-il aimer son prochain ?',
    punchline: 'Parce que les autres sont loin.'
  },
  {
    setup: 'Quel est le sport le plus fruité ?',
    punchline:
      'La boxe, parce que tu te prends des pêches dans la poire et tu tombes dans les pommes.'
  },
  {
    setup: 'Quel est le comble pour un marin ?',
    punchline: 'Avoir le nez qui coule.'
  },
  {
    setup: 'Quel est le comble pour un cheval ?',
    punchline: 'Se mettre quelqu’un à dos.'
  },
  {
    setup: 'Qu’est-ce qu’une carotte au milieu d’une flaque d’eau ?',
    punchline: 'Un bonhomme de neige… Au printemps !'
  },
  {
    setup: 'Deux enfants passent devant un panneau ’Ralentir école primaire’. ',
    punchline:
      '"L’un des deux garçons dit : Tu te rends compte ! Ils ne croient tout de même pas qu’on va y aller en courant !"'
  },
  {
    setup:
      '"Le papa regarde le cahier de travail de Toto. C’est quoi cette page blanche ? Lui demande-t-il."',
    punchline: 'Ça, c’est une page de calcul mental !'
  },
  {
    setup: 'Tu veux entendre une blague de moufette ?',
    punchline: 'Non, je ne le sens pas.'
  },
  {
    setup: 'Veux-tu entendre une blague sur la construction ?',
    punchline: 'Je te la raconte plus tard. J’y travaille encore.'
  },
  {
    setup: 'Qu’est-ce que vous pouvez tenir sans jamais y toucher ?',
    punchline: 'Une conversation.'
  },
  {
    setup: 'Combien de côtés y a-t-il dans un cercle ?',
    punchline: 'Deux. L’intérieur et l’extérieur.'
  },
  {
    setup: 'Qui peut répondre à ta question dans n’importe quelle langue ?',
    punchline: 'Un écho.'
  },
  {
    setup: 'Quelle est la fée la plus méchante ?',
    punchline: 'La fée C.'
  },
  {
    setup: 'Qu’est-ce qui reste dans le coin tout le temps mais qui voyage à travers le monde ?',
    punchline: 'Un timbre.'
  },
  {
    setup: 'Qu’est-ce qui a trois sorties et une seule entrée ?',
    punchline: 'Un t-shirt.'
  },
  {
    setup: 'Quel est le pain que les fées préfèrent ?',
    punchline: 'La baguette.'
  },
  {
    setup: 'Que dit-on d’un vampire bourré d’énergie ?',
    punchline: 'Il a du mordant. '
  },
  {
    setup: 'Quelle est l’expression préférée des vampires ?',
    punchline: 'Bon sang !'
  },
  {
    setup: 'Quel est le comble pour un vampire ?',
    punchline: 'Se faire du mauvais sang.'
  },
  {
    setup: 'Les monstres sont-ils bons en maths ?',
    punchline: 'Non, sauf le Conte Dracula.'
  },
  {
    setup: 'Quel est le comble pour un fantôme ?',
    punchline: 'Se mettre dans de sales draps.'
  },
  {
    setup: '"La maîtresse à Toto : Peux-tu me donner le nom d’un liquide qui ne peut pas geler ?"',
    punchline: 'Euh… L’eau chaude, maîtresse !'
  },
  {
    setup: 'Quel est le comble pour une araignée ?',
    punchline: 'Exposer ses toiles au musée des Beaux-Arts.'
  },
  {
    setup:
      '"Un fantôme dit à un autre fantôme : Hé dis-donc, il y a un mouchoir qui traîne derrière toi !"',
    punchline: 'C’est pas un mouchoir, c’est mon fils !'
  },
  {
    setup: 'Quelle est la meilleure chose à propos de la Suisse ?',
    punchline: 'Je ne sais pas, mais le drapeau est un gros plus.'
  },
  {
    setup:
      '"Un fantôme et un vampire sont tous les deux au bord d’une route déserte. Le fantôme : J’attends le vaisseau fantôme, et vous ?"',
    punchline: 'Le vampire : Moi ? J’attends le vaisseau sanguin…'
  },
  {
    setup: 'Comment appelle-t-on vampire passionné de hockey ?',
    punchline: 'Un mordu.'
  },
  {
    setup: 'Avez-vous entendu parler de l’astronaute claustrophobe ?',
    punchline: 'Il avait juste besoin d’un peu d’espace.'
  },
  {
    setup: 'Quel est le plat préféré des vampires ?',
    punchline: 'Le croque-monsieur.'
  },
  {
    setup: 'Comment appelle-t-on un professeur vampire ?',
    punchline: 'Un ensaignant (enseignant).'
  },
  {
    setup: 'Pourquoi le feu de circulation est-il devenu rouge ?',
    punchline: 'Parce qu’il était gêné de se changer au milieu de la rue. '
  },
  {
    setup: 'Que dit un vampire en quittant sa victime ?',
    punchline: 'Merci beau cou (beaucoup).'
  },
  {
    setup: 'Qu’a dit 0 à 8 ?',
    punchline: 'Belle ceinture.'
  },
  {
    setup: 'Pourquoi certaines espèces de poissons vivent-elles dans l’eau salée ?',
    punchline: 'Parce que si elles vivaient dans l’eau poivrée, elles éternueraient. '
  },
  {
    setup: 'Pourquoi le gamin a-t-il jeté sa montre par la fenêtre ?',
    punchline: 'Parce qu’il voulait que le temps s’envole.'
  },
  {
    setup: 'Pourquoi les sorcières volent-elles sur un balai ?',
    punchline: 'Parce que les aspirateurs sont trop chers.'
  },
  {
    setup: 'Quelle est la danse préférée des sorcières ?',
    punchline: 'Le ballet.'
  },
  {
    setup: 'Je vole et je suis fluorescente, qui suis-je ?',
    punchline: 'Une sorcière qui a foncé dans un fil électrique.'
  },
  {
    setup:
      '"Dracula et son copain sortent un soir, mais le temps est épouvantable ! Est-ce que c’est une tornade ? Demande le copain."',
    punchline: 'Mais non, je te jure que moi j’ai déjà vu des vents pires !'
  },
  {
    setup:
      'Que dit la sorcière à son mari quand elle trouve qu’une chose est très facile à faire ?',
    punchline: 'C’est pas sorcier.'
  },
  {
    setup: 'Quelle est la pierre préférée des fantômes ?',
    punchline: 'La pierre tombale.'
  },
  {
    setup: 'Comment peut-on savoir si un fantôme est sur le point de s’évanouir ?',
    punchline: 'S’il est blanc comme un linge.'
  },
  {
    setup: 'Qui sont les fantômes les plus élégants ?',
    punchline: 'Ceux qui sont dans de beaux draps.'
  },
  {
    setup: 'Est-ce que cela porte marchance d’être poursuivi par un chat ?',
    punchline: 'Pour une souris, oui !'
  },
  {
    setup: '"Un fantôme et un chat noir discutent : Dracula aime tellement ça être un vampire !"',
    punchline: 'Ah oui, il a ça dans le sang !'
  },
  {
    setup: 'Qu’est-ce qui a 4 pattes et un bras ?',
    punchline: 'Un pitbull dans un jardin d’enfants !'
  },
  {
    setup: 'Je me rappellerai toujours des derniers mots de mon grand-père…',
    punchline: 'Arrête de bouger l’échelle !'
  },
  {
    setup:
      'Un homme est au tribunal. Le juge lui demande la raison pour laquelle il a plongé toute la famille dans du métal en fusion.',
    punchline: 'Il répond : Ma mère m’a toujours dit qu’il fallait que je fonde une famille.'
  },
  {
    setup: 'Avec quelle main est-il préférable d’écrire ?',
    punchline: 'Ni l’une ni l’autre, il vaut mieux écrire avec un crayon.'
  },
  {
    setup: 'J’ai un chat dans la gorge !',
    punchline: 'Vu ton haleine, tu as aussi la litière…'
  },
  {
    setup: 'J’ai fait une blague sur les magasins…',
    punchline: 'Mais elle n’a pas super marché.'
  },
  {
    setup: '"Deux pianos discutent. Ça va toi ?"',
    punchline: 'Bof, j’ai mal au do (dos).'
  },
  {
    setup: 'Qu’est-ce qui a quatre roues et des mouches ?',
    punchline: 'Un camion à déchets.'
  },
  {
    setup: 'Tu connais l’histoire de la chaise ?',
    punchline: 'Elle est pliante.'
  },
  {
    setup: 'Qu’est-ce que le feu de circulation dit à la voiture ?',
    punchline: 'Regardez ailleurs, je suis sur le point de me changer.'
  },
  {
    setup:
      'C’est incroyable, je viens de lire dans le journal que chaque fois que je respire, il y a cinq personnes qui meurent dans le monde !',
    punchline: 'Je t’ai déjà dit de changer de dentifrice !'
  },
  {
    setup: 'Une maman allumette dit à ses enfants :',
    punchline: 'Surtout, ne vous grattez pas la tête !'
  },
  {
    setup: '"La maîtresse demande à sa classe : Quand cueille-t-on les cerises ?"',
    punchline: 'Et Toto répond : Quand le propriétaire du cerisier a le dos tourné, maîtresse !'
  },
  {
    setup: 'Pourquoi l’élève a-t-il mangé ses devoirs ?',
    punchline: 'Parce que son professeur lui a dit que ce serait de la tarte !'
  },
  {
    setup:
      '"Les policiers arrêtent un automobiliste pour un contrôle d’identité sur la route. L’un des policiers dit : Papiers !"',
    punchline: 'Le conducteur lui répond : Ciseaux ! J’ai gagné !'
  },
  {
    setup: 'Pourquoi les fantômes sont-ils de mauvais menteurs ?',
    punchline: 'Parce qu’ils sont transparents.'
  },
  {
    setup:
      '"Celui qui, tout au long de la journée : Est actif comme une abeille, Est fort comme un bœuf, Travail comme un cheval, Et qui le soir venu est crevé comme un chien…"',
    punchline: 'Devrait consulter un vétérinaire, il est fort probable que ce soit un âne !'
  },
  {
    setup: 'Quand j’ai acheté mon système de son, le vendeur m’a dit : ’niveau son, ça déménage !’',
    punchline: 'C’était vrai : au bout de deux semaines mes voisins du dessus ont déménagé !'
  },
  {
    setup:
      'L’administration publique est le seul endroit où l’on ne risque pas de voler la pendule qui est au mur…',
    punchline: 'Tout le monde a les yeux fixés dessus.'
  },
  {
    setup: 'Que se disent deux fonctionnaires qui se croisent dans le couloir au bureau ?',
    punchline: 'Ah, toi aussi tu as des insomnies ?'
  },
  {
    setup:
      '"Un médecin se plaint auprès d’un patient : J’attends toujours mes honoraires, votre chèque m’est revenu impayé !"',
    punchline: 'C’est logique, répond le patient, j’ai rechuté !'
  },
  {
    setup: '"Un client revient chez le pharmacien. Votre dentifrice a un goût infect."',
    punchline: 'Et alors ? De toute façon, vous le recrachez !'
  },
  {
    setup:
      'Docteur, ce matin en me regardant dans le miroir, j’ai constaté que j’avais un œil qui n’était pas identique à l’autre.',
    punchline: 'Ha oui, lequel ?'
  },
  {
    setup: 'Le patient : Docteur, j’ai besoin de lunettes. ',
    punchline: 'La personne en face de lui : Oui certainement, ici c’est une banque !'
  },
  {
    setup: 'Docteur, je suis tellement stressée. C’est ma première opération.',
    punchline: 'Ne vous inquiétez pas, moi aussi, c’est ma première opération.'
  },
  {
    setup: 'Pourquoi le livre de mathématiques était-il triste ?',
    punchline: 'Il avait pleins de problèmes.'
  },
  {
    setup: 'Il doit y avoir quelque chose de vrai dans l’acupuncture…',
    punchline: 'On n’a jamais vu un hérisson malade !'
  },
  {
    setup: 'Quand une blague devient-elle une ’blague à papa’ ?',
    punchline: 'Quand elle devient évidente.'
  },
  {
    setup: 'Le travail d’équipe, c’est essentiel…',
    punchline: 'En cas d’erreur, ça permet d’accuser quelqu’un d’autre.'
  },
  {
    setup: '"Ce sont deux bucherons qui discutent : Moi je vais rester bucheron toute ma vie."',
    punchline: 'Moi je vais changer de bouleau (boulot).'
  },
  {
    setup:
      '"Un patron dit à son employé : C’est la quatrième fois que vous arrivez en retard cette semaine, que dois-je en déduire ?"',
    punchline: 'Que nous sommes jeudi ?'
  },
  {
    setup: 'Quelle est la différence entre un livreur de pizzas et un citron ?',
    punchline: 'Aucune : ils sont tous les deux pressés !'
  },
  {
    setup: 'Pourquoi voulez-vous cet emploi ?',
    punchline: 'En fait, j’ai toujours été passionné par ne pas mourir de faim.'
  },
  {
    setup: 'Qu’est-ce qu’une maman fantôme offre à sa fille pour Noël ?',
    punchline: 'Une maison de poupée hantée.'
  },
  {
    setup: 'Quel spectacle les écureuils vont-ils voir à Noël ?',
    punchline: 'Casse-noisette.'
  },
  {
    setup: 'Quel est le gâteau le plus dur au monde ?',
    punchline: 'La buche de Noël.'
  },
  {
    setup:
      '"La maman de Toto cherche partout dans les armoires de la cuisine et finit par demander à son fils : Toto tu n’aurais pas vu la passoire ?"',
    punchline: 'Oui maman, je l’ai jetée, elle était pleine de trous !'
  },
  {
    setup: 'Tu veux entendre une blague sur un ballon ?',
    punchline: 'Le Père Noël et ses rennes.'
  },
  {
    setup: 'Qu’est-ce qui a 34 jambes, 9 têtes et 2 bras ?',
    punchline: 'A Do-you-think-he-sarus!'
  },
  {
    setup: 'Que dit un sapin de Noël qui arrive en retard le soir du réveillon ?',
    punchline: 'Je vais encore me faire enguirlander.'
  },
  {
    setup: 'Quelle est la pire chose à propos du mal de mer ?',
    punchline: 'Il vient par vagues. '
  },
  {
    setup:
      '"Circulant sur une autoroute, un automobiliste entend un appel à la radio : Attention : on nous signale une voiture roulant dans le sens inverse !"',
    punchline: 'Une voiture ? Des centaines, oui !'
  },
  {
    setup: 'Alors cet examen ? Demande un papa kangourou à sa fille qui rentre de l’école.',
    punchline: 'Pas de problème, c’est dans la poche !'
  },
  {
    setup: 'Quel est le point commun entre un policier et une cocotte-minute ?',
    punchline: 'Quand il sifle, c’est cuit !'
  },
  {
    setup: 'Quand peux-tu y aller au rouge et dois arrêter au vert ?',
    punchline: 'Lorsque tu manges un melon d’eau.'
  },
  {
    setup: 'Quel est le comble pour l’homme invisible ?',
    punchline: 'Être mal vu par tout le monde.'
  },
  {
    setup:
      'A toutes les fois que ma grand-mère demande à mon père : pis, comment t’as dormi la nuit passée ?',
    punchline: 'Mon père répond toujours : Avec les yeux fermés.'
  },
  {
    setup:
      '"Le papa de Toto questionne son fils : Dis-moi, Toto… Il y avait deux crèmes glacées dans le congélateur. Peux-tu m’expliquer pourquoi il n’en reste qu’une ?"',
    punchline: 'Parce que je n’avais pas vu la deuxième, papa !'
  },
  {
    setup:
      'Toto a un secret à confier à son père et lui demande de s’approcher pour que personne n’entende.',
    punchline:
      '"Lorsqu’ils ont leurs têtes collées l’une contre l’autre, Toto dit doucement à son père : Papa, j’ai des poux…"'
  },
  {
    setup: 'La maîtresse demande à ses élèves de rédiger une histoire courte qui fait peur. ',
    punchline:
      'Toto est le premier à rendre sa copie et la maîtresse qui lit ce qu’il a écrit : Au secours ! Au secours !'
  },
  {
    setup: 'Toto part au camp d’été et écrit une carte postale à sa maman.',
    punchline:
      'Maman, il fait beau. Tout va bien, mais tu as oublié d’écrire mon nom sur mes vêtements. Du coup, tout le monde m’appelle 100% coton !'
  },
  {
    setup: 'Why did the man fall down the well?',
    punchline: 'Because he couldn’t see that well.'
  },
  {
    setup: '"À l’école… Quel est l’imparfait du verbe parler ? Demande la maîtresse."',
    punchline: 'Bégayer, répond Toto.'
  },
  {
    setup:
      '"La maîtresse interroge Toto : Imagine que tu as un pantalon avec 15 dollars dans la poche droite et 6 dollars dans la poche gauche. Qu’est-ce que tu as ?"',
    punchline: 'Ben… Un pantalon qui n’est pas à moi !'
  },
  {
    setup: 'Pourquoi les gros pingouins sont-ils si populaires lors des fêtes ?',
    punchline: 'Ils savent comment briser la glace.'
  },
  {
    setup:
      '"La maîtresse demande à Toto : Mets cette phrase à la forme impérative : Le cheval tire la charette."',
    punchline: 'Et Toto répond : Allez, hue !'
  },
  {
    setup: 'La maîtresse demande à Toto s’il a passé de bonnes vacances.',
    punchline: 'Toto lui répond : Bof… Pas assez pour faire une rédaction !'
  },
  {
    setup: 'Quelle est la lettre la plus froide de l’alphabet ?',
    punchline: 'B qui est entre AC.'
  },
  {
    setup: 'Pourquoi ne devrait-on pas se fâcher contre les gens paresseux ?',
    punchline: 'Parce qu’ils n’ont rien fait.'
  },
  {
    setup:
      '"La maîtresse rend aux élèves leurs rédactions…Toto, c’est étrange, ta rédaction sur les chiens est mot pour mot celle de ton copain Rémi !"',
    punchline: 'C’est normal madame, on parlait du même chien !'
  },
  {
    setup: 'Qu’est-ce qui se trouve sur une branche d’arbre et qui fait Aaaargh ?',
    punchline: 'Un hibou avec un trouble de la parole.'
  },
  {
    setup: 'La maîtresse demande à sa classe : Qui peut expliquer ce qu’est un synonyme ?',
    punchline:
      '"Toto répond : Oui, moi madame ! C’est un mot qu’on utilise quand on ne sait pas comment l’autre s’écrit."'
  }
]
