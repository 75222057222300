export default [
  {
    setup: '¿Cuál es la lengua menos hablada del mundo?',
    punchline: 'Lenguaje de señas'
  },
  {
    setup: '¿Qué come un vegetariano zombi?',
    punchline: '¡GRRRAAAAANNNNOS!'
  },
  {
    setup: '¿Qué dijo el pirata en su cumpleaños número 80?',
    punchline: '¡ARRGH!'
  },
  {
    setup: '¿Por qué el hombre era atropellado por una bicicleta todos los días?',
    punchline: 'Estaba atrapado en un ciclo vicioso.'
  },
  {
    setup: '¿Por qué el hombre invisible rechazó una oferta de trabajo?',
    punchline: 'No se veía haciéndolo.'
  },
  {
    setup: '¿Por qué los elefantes se arrugan?',
    punchline: 'Porque no puedes plancharlos.'
  },
  {
    setup: '¿Qué le dijo el policía a su ombligo?',
    punchline: '¡Estás bajo el resto!'
  },
  {
    setup: '¿Cómo se quemó la lengua el hipster?',
    punchline: 'Tirándolo a la corriente.'
  },
  {
    setup: '¿Qué tipo de té es difícil de tragar?',
    punchline: 'El "Té" lo dije.'
  },
  {
    setup: '¿Cómo ganó el barbero la carrera?',
    punchline: 'Sabía por donde cortar.'
  },
  {
    setup: 'Descansa en paz agua hirviendo',
    punchline: '¡Serás niebla!'
  },
  {
    setup: '¿Cómo haces una fiesta espacial?',
    punchline: 'La Planetas'
  },
  {
    setup: '¿Alguna vez has intentado comerte un reloj?',
    punchline: 'Te quita mucho tiempo, especialmente si te vas primero por los segundos.'
  },
  {
    setup: '¿Cómo llamas a un perro mágico?',
    punchline: 'Un ladracadabrador'
  },
  {
    setup: '¡Inventé una nueva palabra!',
    punchline: '¡Plagio!'
  },
  {
    setup: '¿Has oído hablar del matemático que le teme a los números negativos?',
    punchline: 'No se detendrá ante nada para evitarlos.'
  },
  {
    setup: 'Helvetica y Times New Roman entran a un bar.',
    punchline: '"¡Salgan de aquí!", grita el barman. "No le servimos a los de su tipo".'
  },
  {
    setup: '¿Escuchaste sobre el nuevo restaurante llamado Karma?',
    punchline: 'No hay menú. Te dan lo que te mereces.'
  },
  {
    setup:
      'Una mujer en trabajo de parto gritó de repente: "¡No debería! ¡No lo haría! ¡No pude! ¡No lo hice! ¡Hipocresía!"',
    punchline: 'No se preocupe, dijo el doctor. "Esas son solo contracciones".'
  },
  {
    setup: '¿Por qué los científicos no confían en los átomos?',
    punchline: 'Porque lo inventan todo..'
  },
  {
    setup: '¿Dónde se fabrican las cosas normales?',
    punchline: 'En la satis-factoría.'
  },
  {
    setup: '¿Por qué no puedes explicar los juegos de palabras a los cleptómanos?',
    punchline: 'Siempre toman las cosas, literalmente.'
  },
  {
    setup: 'Un hombre le dice a su médico: “Doc, ayúdeme. ¡Soy adicto a Twitter! "',
    punchline: 'El médico responde: "Lo siento, no te sigo."'
  },
  {
    setup: '¿Qué le dijo el ojo izquierdo al ojo derecho?',
    punchline: 'Entre tú y yo, algo huele.'
  },
  {
    setup: '¿Qué le dijo un sombrero al otro?',
    punchline: 'Espera aqui. Iré a la cabeza.'
  },
  {
    setup: '¿Qué dijo el tiburón cuando se comió el pez payaso?',
    punchline: 'Fue divertido.'
  },
  {
    setup: '¿Por qué la Oreo fue al dentista?',
    punchline: 'Porque perdió el relleno.'
  },
  {
    setup: '¿Qué obtienes de una vaca mimada?',
    punchline: 'Mala leche.'
  },
  {
    setup: '¿Por qué el M&M fue a la escuela?',
    punchline: 'Quería ser un Smartie.'
  },
  {
    setup: 'Descanse en paz al agua hirviendo.',
    punchline: 'Serás niebla.'
  },
  {
    setup: 'Los números 19 y 20 se pelearon.',
    punchline: '21.'
  },
  {
    setup: '¿Por qué el libro de texto de matemáticas visitó al consejero académico?',
    punchline: 'Necesitaba ayuda para resolver sus problemas.'
  },
  {
    setup: 'Dos peces dorados están en un tanque.',
    punchline: 'Uno mira al otro y dice: "¿Sabes cómo conducir esta cosa?"'
  },
  {
    setup: 'Esperé y me quedé despierto toda la noche y traté de averiguar dónde estaba el sol.',
    punchline: 'Entonces me di cuenta.'
  },
  {
    setup: 'A veces meto las rodillas en el pecho y me inclino hacia adelante.',
    punchline: 'Así es como ruedo.'
  },
  {
    setup: 'Recuerda, el trabajo en equipo es importante.',
    punchline: 'Ayuda a echarle la culpa a otra persona.'
  },
  {
    setup: 'Me despidieron de la fábrica de calendarios.',
    punchline: 'No sé por qué, todo lo que hice fue tomar un día libre.'
  },
  {
    setup: '¿Quieres escuchar un chiste sobre una mofeta?',
    punchline: 'No, gracias, realmente apesta.'
  },
  {
    setup: '¿Quieres escuchar un chiste sobre la construcción?',
    punchline: 'Te lo diré más tarde, todavía estoy haciéndolo.'
  },
  {
    setup: '¿Qué puedes sostener sin ni siquiera tocar?',
    punchline: 'Una conversación.'
  },
  {
    setup: '¿Cuántos lados tiene un círculo?',
    punchline: 'Dos. El interior y el exterior.'
  },
  {
    setup: '¿Qué puede responder a tu pregunta en cualquier idioma?',
    punchline: 'Un eco.'
  },
  {
    setup: '¿Qué tiene tres salidas y una sola entrada?',
    punchline: 'Una camiseta.'
  },
  {
    setup: '¿Qué raza de perro puede saltar más alto que los edificios?',
    punchline: 'Cualquier perro, porque los edificios no pueden saltar.'
  },
  {
    setup: '¿Has oído hablar del matemático que le teme a los números negativos?',
    punchline: 'No se detendrá ante nada para evitarlos.'
  },
  {
    setup: '¿Escuchaste sobre el astronauta claustrofóbico?',
    punchline: 'Solo necesitaba un poco de espacio.'
  },
  {
    setup: '¿Por qué los científicos no confían en los átomos?',
    punchline: 'Porque lo inventan todo.'
  },
  {
    setup: '¿Por qué se puso rojo el semáforo?',
    punchline: '¡Porque le daba vergüenza estar cambiándose en medio de la calle!'
  },
  {
    setup: '¿Qué le dijo 0 a 8?',
    punchline: 'Buen cinturón.'
  },
  {
    setup: '¿Por qué los peces viven en agua salada?',
    punchline: 'Porque si vivieran en agua con pimienta, estornudarían.'
  },
  {
    setup: '¿Por qué el niño arrojó su reloj por la ventana?',
    punchline: 'Porque quería ver volar el tiempo.'
  },
  {
    setup: '¿Dónde guardan su dinero los osos polares?',
    punchline: '¡En un banco de nieve!'
  },
  {
    setup: '¿Por qué Cenicienta es mala en el fútbol?',
    punchline: '¡Porque siempre se la pasa huyendo!'
  },
  {
    setup: '¿Qué le dijo un ojo al otro?',
    punchline: 'Entre nosotros, algo huele.'
  },
  {
    setup: '¿Por qué la galleta fue a la enfermera?',
    punchline: 'Porque sentía que todo le crujía.'
  },
  {
    setup: '¿Cómo llamas a un gato mentiroso?',
    punchline: 'Un león.'
  },
  {
    setup: '¿Con qué mano es mejor escribir?',
    punchline: 'Ninguna, es mejor escribir con un lápiz.'
  },
  {
    setup: '¿Qué hace el océano cuando ve a sus amigos?',
    punchline: 'Dice: "Hola".'
  },
  {
    setup: '¿Qué le dijo un muñeco de nieve a otro muñeco de nieve?',
    punchline: 'Huele a zanahorias por aquí.'
  },
  {
    setup: '¿Por qué el golfista usa dos pares de pantalones?',
    punchline: '¡Por si acaso hace un hoyo en uno.'
  },
  {
    setup: '¿Cómo se hace reír a un pulpo?',
    punchline: 'Con diez cosquillas.'
  },
  {
    setup: '¿Qué le dijo el semáforo al coche?',
    punchline: 'Mira hacia otro lado que me voy a cambiar.'
  },
  {
    setup: '¿Qué se vuelve más húmedo si está seco?',
    punchline: 'Una toalla.'
  },
  {
    setup: '¿Por qué el osito de peluche no pidió postre?',
    punchline: '¡Porque ya estaba muy lleno!'
  },
  {
    setup: '¿Cómo llamas a un fideo falso?',
    punchline: '¡Un impastar!'
  },
  {
    setup: '¿Por qué una mano no puede medir 12 pulgadas?',
    punchline: '¡Porque entonces sería un pie!'
  },
  {
    setup: '¿Por qué el estudiante se comió su tarea?',
    punchline: '¡Porque su profesor le dijo que sería pan comido!'
  },
  {
    setup: '¿Por qué los fantasmas son malos mentirosos?',
    punchline: '¡Porque puedes ver a través de ellos!'
  },
  {
    setup: '¿Qué oso es el más condescendiente?',
    punchline: '¡Un pan-dahhh!'
  },
  {
    setup: '¿Cómo se llamaría Terminator en su retiro?',
    punchline: 'El Exterminador.'
  },
  {
    setup: '¿Cuál es el océano más orientado a los detalles?',
    punchline: 'El Pacífico.'
  },
  {
    setup: '¿Cuál es la contraseña de Forrest Gump?',
    punchline: '1Forrest1'
  },
  {
    setup: '¿Cómo llaman los dentistas a sus radiografías?',
    punchline: 'Fotos de dientes!'
  },
  {
    setup: '¿Cuál es la fruta favorita de Beethoven?',
    punchline: 'BA-NA-NA-NAAAA'
  },
  {
    setup: '¿Por qué estaba triste el libro de matemáticas?',
    punchline: 'Tenía demasiados problemas.'
  },
  {
    setup: '¿Cuándo se convierte un chiste en un "chiste de papá"?',
    punchline: 'Cuando se hace evidente.'
  },
  {
    setup: '¿Qué tipo de zapatos usa una persona perezosa?',
    punchline: 'Mocasines'
  },
  {
    setup: '¿Cómo llamó el baterista a sus hijas gemelas?',
    punchline: 'Anna One, Anna Two!'
  },
  {
    setup: '¿Qué hace un pimiento entrometido?',
    punchline: 'Se mete entre unos jalapeños.'
  },
  {
    setup: '¿Por qué ganó un premio el espantapájaros?',
    punchline: 'Se destacó en su campo.'
  },
  {
    setup:
      '¿Quieres escuchar un chiste sobre un globo? ¿Quieres escuchar un chiste sobre un globo?',
    punchline: 'Espera, ¡se me escapó!'
  },
  {
    setup: '¿Cómo llamas a una madre pequeña?',
    punchline: 'Una míni-ma'
  },
  {
    setup: '¿Qué es lo peor de los mareos?',
    punchline: 'Viene en oleadas.'
  },
  {
    setup: '¿Qué le dijo el océano a la orilla?',
    punchline: 'Nada, solo "hola".'
  },
  {
    setup: '¿Cuándo vas al rojo y te detienes en el verde?',
    punchline: 'Cuando comes una sandía.'
  },
  {
    setup: '¿Cómo llamas a una vaca sin piernas?',
    punchline: 'Carne molida.'
  },
  {
    setup: '¿Cómo llamas a un grupo de orcas tocando instrumentos?',
    punchline: 'Una or-questa'
  },
  {
    setup: '¿Cuántas cosquillas se necesitan para hacer reír a un pulpo?',
    punchline: 'Diez cosquillas'
  },
  {
    setup: '¿Cómo se llama a una fábrica que vende productos aceptables?',
    punchline: 'Una satis-factoría.'
  },
  {
    setup: '¿Por qué Santa estudió música en la universidad?',
    punchline: 'Para mejorar sus habilidades manuales.'
  },
  {
    setup: '¿Qué hace un pimiento enojado?',
    punchline: 'Se pone cara de jalapeño.'
  },
  {
    setup: '¿Quieres escuchar un chiste sobre un papel?',
    punchline: 'No te creas, se puede romper'
  },
  {
    setup: '¿Por qué los pingüinos gordos son tan populares en las fiestas?',
    punchline: 'Saben cómo romper el hielo.'
  },
  {
    setup: '¿Qué le dijo el esquiador al terapeuta?',
    punchline: 'Mi vida va cuesta abajo.'
  },
  {
    setup: '¿Cómo se llama a un hombre sin brazos ni piernas en medio del mar?',
    punchline: 'Bob'
  },
  {
    setup: '¿Por qué uno no debería enojarse con la gente floja?',
    punchline: 'Porque no han hecho nada.'
  },
  {
    setup: '¿Dónde compras cosas moradas?',
    punchline: 'En la Lavand-ería'
  },
  {
    setup: '¿Cuándo debería jubilarse un astronauta?',
    punchline: 'Cuando empieza a pedir espacio.'
  },
  {
    setup: 'Como espantapájaros, la gente dice que soy sobresaliente en mi campo.',
    punchline: 'Pero henos aquí.'
  },
  {
    setup: '¿Por qué no deberías dejar que las matemáticas avanzadas te intimiden?',
    punchline: '¡Por que es igual de fácil que pi!'
  },

  {
    setup: '¿Qué se sienta en un árbol y dice "Aaaargh"?',
    punchline: 'Un búho con impedimento del habla.'
  },
  {
    setup: '¿Cómo saben los piratas que son piratas?',
    punchline: 'Ellos piensan, luego ARRRR !!!!!'
  }
]
